<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { merge } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  props: {
    formUrl: {
      type: String,
      default: "/upload_simple_form"
    }
  },
  data() {
    return {
      form: {
        success: false,
        errors: [],
        loading: false,
        data: {},
      },
      currentStepIndex: 0,
      currentStep: this.content.stripes[0],
    }
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    const page = params.get("page");
    this.content.stripes.forEach(s => {
      const fields = JSON.parse(s.form_fields || '{}').fields || []
      fields.forEach(field => {
        Vue.set(this.form.data, field.name, this.pageModel.user[field.name]);
      })
    })
    if (page && page != 1) {
      this.currentStepIndex = parseInt(page) - 1;
      this.currentStep = this.content.stripes[this.currentStepIndex];
    }
  },
  methods: {
    updateFormData(data) {
      const updateFunction = () => {
        Vue.set(this.form, 'data', merge({}, this.form.data, data));
        if (this.currentStepIndex === (this.steps.length - 1)) {
          this.submitForm();
        } else {
          this.currentStepIndex += 1;
          this.currentStep = null;
        }
      }
      
      if (this.currentStep.precompile_source_field) {
        this.checkPrecompileField(this.currentStep.precompile_source_field, data[this.currentStep.precompile_source_field], updateFunction);
      } else {
        updateFunction();
      }
    },
    checkPrecompileField(key, value, cb) {
      Fandom.ajax({
        url: '/simple-form/precompile',
        data: {
          key,
          value,
          url: `${window.location.host}${window.location.pathname}`
        },
        success: (data) => {
          if (data.found) {
            alert(this.ft("globals.email_already_present"));
          } else {
            cb();
          }
        },
        error: (err) => {
          cb();
        }
      })
    },
    setCurrentStep() {
      Vue.set(this, 'currentStep', this.steps[this.currentStepIndex]);
      $("html, body").animate({
        scrollTop: 0
      }, 0, "linear");
    },
    submitForm() {
      if (!this.form.loading) {
        this.form.success = false;
        this.formLoading = true;
        this.form.errors = null;
        let submitForm = () => {
          Fandom.ajax(
            {
              async: true,
              processData: false,
              contentType: false,
              method: "POST",
              url: this.formUrl,
              data: this.getFormData(),
              success: (data) => {
                this.form.success = data.success;
                if (this.form.success) {
                  this.form.progress = 0;
                  if (Fandom.exists(this.content.thank_you_page)) {
                    this.successMessage = null;
                    const thankYouUrl = this.content.thank_you_page.hasOwnProperty("url") ? this.content.thank_you_page.url : this.content.thank_you_page;
                    window.location.href = Fandom.applyContextToUrl(thankYouUrl);
                  } else if (!this.content.precompile) {
                    this.resetFormData();
                  }
                } else {
                  this.form.errors = data.errors;
                }
              }
            },
            () => {
              this.progress = null;
              this.form.loading = false;
            }
          );
        }
        submitForm();
      }
    },
    getFormData() {
      const formData = new FormData();
      formData.append("content_id", this.containerContent.id);
      formData.append("interaction_id", this.content.interaction.id);
      this.formFields.forEach(field => {
        const formDataValue = this.form.data[field.name];
        if (!!formDataValue) {
          formData.append(`user_upload_interaction[${field.name}]`, formDataValue);
        }
      });
      return formData;
    },
  },
  computed: {
    progressPercentage() {
      return (this.stepNumber / this.stepsNumber) * 100;
    },
    stepsNumber() {
      return this.steps.length;
    },
    stepNumber() {
      return this.currentStepIndex + 1;
    },
    steps() {
      return this.content.stripes.filter(s => !Fandom.isUserRegistered() || !s.hide_to_registered_user);
    },
    formFields() {
      let fields = []
      this.content.stripes.forEach(s => {
        const sFields = JSON.parse(s.form_fields || '{}').fields;
        fields = [...fields, ...sFields];
      });
      return fields;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" page-event-registration-component>
    <div v-if="!(currentStep && currentStep.background_color)" class="position-relative w-100 progress-bar-container">
      <div class="position-absolute h-100 progress-bar" :style="{'width': `${progressPercentage}%`}"></div>
    </div>
    <transition
      enter-active-class="animated fadeInRight faster"
      leave-active-class="animated fadeOutLeft faster"
      @after-leave="setCurrentStep"
    >
      <component
        v-if="currentStep"
        :is="currentStep.layout"
        :key="currentStepIndex"
        :content="currentStep"
        :opt-container-content="containerContent"
        :init-data="pageModel.user"
        :errors="form.errors"
        @update="updateFormData"
      ></component>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[page-event-registration-component] {
  background-color: $secondary;
  .progress-bar-container {
    height: .3rem;
    background-color: $primary;
    .progress-bar {
      top: 0;
      left: 0;
      background-color: #735338;
      transition: width .5s ease-in-out;
    }
  }
}
</style>