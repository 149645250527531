<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      toDelete: undefined,
      feedback: { show: false },
      feedbackTimeout: null,
      filters: {
        site: undefined,
        visitType: undefined,
        datesInterval: [undefined, undefined]
      },
      filtersModalOpened: false,
      mail: {
        progress: 0,
        totalPages: null
      }
    }
  },
  mounted() {
    const params = Fandom.getQueryStringParams();
    if (params.success_message) this.showFeedback(params.success_message, true);
    if (params.error_message) this.showFeedback(params.error_message, false);
    history.replaceState({}, '', window.location.href.split("?")[0]);
  },
  methods: {
    deleteVisit() {
      if (!!this.toDelete) {
        Fandom.ajax({
          url: `/simple-form/visit/${this.toDelete}`,
          method: 'DELETE',
          success: () => {
            Vue.set(this.eventsTag, 'children', this.eventsTag.children.filter(c => c !== this.toDelete));
            Vue.delete(this.pageModel.name_to_content, this.toDelete);
            this.showFeedback(this.ft('globals.messages.visit_delete.success'), true);
          },
          error: (err) => {
            this.showFeedback(err.responseJSON.errors.join(","), false);
          }
        }, () => this.hideDeleteModal());
      } else {
        console.warn(this.ft('globals.messages.visit_delete.fail'));
        this.hideDeleteModal();
      }
    },
    showFeedback(message, success) {
      this.feedback.show = false;
      clearTimeout(this.feedbackTimeout);
      Vue.nextTick(() => {
        Vue.set(this, 'feedback', {
          text: message,
          show: true,
          type: success ? 'success' : 'danger',
          icon: success ? 'fa-check-circle' : 'fa-times-octagon'
        });
        Vue.set(this, 'feedbackTimeout', setTimeout(() => this.feedback.show = false, 5000));
      });
    },
    getSite(contentName) {
      const content = this.getContent(contentName);
      return get(this.pageModel, ["decorators", "site", content.decorators["site"]], {});
    },
    showDeleteModal(content) {
      Vue.set(this, 'toDelete', content);
      $(this.$refs["deleteModal"]).modal("show");
    },
    hideDeleteModal() {
      Vue.set(this, 'toDelete', undefined);
      $(this.$refs["deleteModal"]).modal("hide");
    },
    showFilterModal() {
      this.filtersModalOpened = true;
      Vue.nextTick(() => {
        disableBodyScroll(this.$refs["filters-modal"]);
      });
    },
    hideFilterModal() {
      clearAllBodyScrollLocks();
      this.filtersModalOpened = false;
    },
    resetFilters(keys) {
      keys.forEach(k => {
        if (k.includes(".")) {
          const subKeys = k.split(".");
          Vue.set(this.filters[subKeys[0]], parseInt(subKeys[1]), undefined);
        } else {
          Vue.set(this.filters, k, undefined);
        }
      })
    },
    sendMails(event) {
      this.showMailProgressModal();
      this.sendMailsRequest(event);
    },
    sendMailsRequest(event) {
      Vue.set(event, "waiting_for_mail", true);
      Fandom.ajax({
        url: `/simple-form/visit/${event.name}/send_mails`,
        dataType: "json",
        success: (response) => {
          if (!this.mail.totalPages) {
            Vue.set(this.mail, "totalPages", response.total_pages);
          }
          const progress = (100 / this.mail.totalPages) * (this.mail.totalPages - response.total_pages + 1);
          Vue.set(this.mail, "progress", progress);
          if (!response.last_page) {
            this.sendMailsRequest(event);
          } else {
            setTimeout(() => {
              this.hideMailProgressModal();
              Vue.set(this.mail, "totalPages", null);
              Vue.set(this.mail, "progress", 0);
            }, 1000);
            Vue.set(event, "waiting_for_mail", false);
            Vue.set(event, "mail_sent", true);
            this.showFeedback(this.ft("globals.email_sent_correctly"), true);
          }
        },
        error: (err) => {
          console.error(err);
          Vue.set(event, "waiting_for_mail", true);
          this.showFeedback(this.ft("globals.email_sent_errors"), true);
        }
      })
    },
    showMailProgressModal() {
      $(this.$refs["mailProgress"])[0].start();
    },
    hideMailProgressModal() {
      $(this.$refs["mailProgress"])[0].stop();
    },
    getEventDate(event) {
      const eventContent = Fandom.getContent(event);
      if (Date.parse(eventContent.valid_to) - Date.parse(eventContent.valid_from) < (60*60*24*1000))
        return this.getDate(eventContent.valid_from);
      else
        return `${this.getDate(eventContent.valid_from)} - ${this.getDate(eventContent.valid_to)}`
    }
  },
  computed: {
    eventsTag() {
      return Fandom.getContent(this.content.visits_tag);
    },
    events() {
      return this.eventsTag.children
        .filter(c => !!this.getContent(c).valid_from && !this.getContent(c).general_access_instance)
        .filter(c => !this.filters.site || this.getContent(c).decorators["site"] == this.filters.site)
        .filter(c => !this.filters.visitType || this.getContent(c).decorators["visit-type"] == this.filters.visitType)
        .filter(c => !this.filters.datesInterval[0] || this.getContent(c).valid_from.split("T")[0] >= this.filters.datesInterval[0])
        .filter(c => !this.filters.datesInterval[1] || this.getContent(c).valid_from.split("T")[0] <= this.filters.datesInterval[1])
        .sort((a,b) => a.date === b.date ? 0 : a.date > b.date ? 1 : -1);
    },
    today() {
      const today = new Date();
      today.setUTCHours(0,0,0,0);
      return today;
    },
    activeEvents() {
      return this.events.filter(e => new Date(this.getContent(e).valid_to) >= this.today);
    },
    pastEvents() {
      return this.events.filter(e => new Date(this.getContent(e).valid_to) < this.today);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container py-4" :class="contentType" page-all-events-component>
    <div v-if="feedback.show" class="alert pb-1 pt-2 small font-weight-bold d-flex justify-content-between align-items-center" :class="`alert-${feedback.type}`" role="alert">
      <span class="pr-5"><i class="far mr-2" :class="feedback.icon"></i> {{feedback.text}}</span>
      <i class="far fa-lg fa-times cursor-pointer" @click="feedback.show = false"></i>
    </div>
    <header class="text-center mb-5">
      <h1 v-if="content.title">{{content.title}}</h1>
      <div v-if="content.description" v-html="content.description"></div>
    </header>
    <div class="d-flex justify-content-between flex-wrap mb-3">
      <div class="d-flex">
        <a v-if="content.create_link" class="btn btn-dark-brown text-dark border-0 mr-2 mb-2" :href="content.create_link.url" :target="getTarget(content.create_link)">
          <span>{{ft('globals.new')}}</span>
          <i class="far fa-plus ml-3"></i>
        </a>
        <button class="btn btn-dark-brown text-dark border-0 mr-2 mb-2" @click="showFilterModal">
          <span>{{ft('globals.filter')}}</span>
          <i class="far fa-sliders-h ml-3"></i>
        </button>
      </div>
      <div class="d-flex">
        <a v-if="content.user_admin_link" class="btn btn-dark-brown text-dark border-0 mb-2 mr-3" :href="content.user_admin_link.url" :target="getTarget(content.user_admin_link)">
          <span>{{ft('globals.user_admins')}}</span>
          <i class="far fa-users-cog ml-3"></i>
        </a>
        <a v-if="content.reports_link" class="btn btn-dark-brown text-dark border-0 mb-2" :href="content.reports_link.url" :target="getTarget(content.reports_link)">
          <span>{{ft('globals.reports')}}</span>
          <i class="far fa-chart-bar ml-3"></i>
        </a>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div v-if="filters.site" class="px-2 pt-1 bg-primary rounded-pill mr-2">
        {{pageModel.decorators["site"][filters.site].title}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['site'])"></i>
      </div>
      <div v-if="filters.visitType" class="px-2 pt-1 bg-primary rounded-pill mr-2">
        {{pageModel.decorators["visit-type"][filters.visitType].title}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['visitType'])"></i>
      </div>
      <div v-if="filters.datesInterval[0]" class="px-2 pt-1 bg-primary rounded-pill mr-2">
        {{ft('globals.after')}} {{getDate(filters.datesInterval[0])}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['datesInterval.0'])"></i>
      </div>
      <div v-if="filters.datesInterval[1]" class="px-2 pt-1 bg-primary rounded-pill">
        {{ft('globals.before')}} {{getDate(filters.datesInterval[1])}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['datesInterval.1'])"></i>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-borderless">
        <tbody>
          <tr v-for="event in activeEvents" :key="getContent(event).id">
            <td>
              <div class="d-flex flex-column justify-content-center bg-primary h-100">
                <strong>{{getContent(event).title}}</strong>
                <div>
                  {{getSite(event).title}} 
                  <span v-if="getContent(event).location" v-html="`(${getContent(event).location})`"></span>
                  <span v-else="!!getSite(event).description" v-html="`(${getSite(event).description})`"></span>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-end bg-primary h-100">
                {{getEventDate(event)}}
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-end bg-primary h-100 manage-container">
                <a :href="`${content.people_update_link.url}?ugc_content_name=${event}`" :target="getTarget(content.update_link)" class="btn btn-secondary p-2 border-0 d-flex justify-content-center align-items-center">
                  <i class="far fa-user-friends"></i>
                </a>
                <a :href="`${content.materials_link.url}?ugc_content_name=${event}`" :target="getTarget(content.update_link)" class="btn btn-secondary p-2 ml-2 border-0 d-flex justify-content-center align-items-center">
                  <i class="far fa-file-upload"></i>
                </a>
                <a :href="`${content.update_link.url}?ugc_content_name=${event}`" :target="getTarget(content.update_link)" class="btn btn-secondary p-2 ml-2 border-0 d-flex justify-content-center align-items-center">
                  <i class="far fa-edit"></i>
                </a>
                <button class="btn btn-secondary p-2 border-0 ml-2 d-flex justify-content-center align-items-center" @click="showDeleteModal(event)">
                  <i class="far fa-trash"></i>
                </button>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center bg-primary h-100">
                <button
                  :title="(!getContent(event).children || getContent(event).waiting_for_mail) ? ft('globals.cannot_send') : ft('globals.send')"
                  @click="sendMails(getContent(event))" class="btn w-100 border-0 text-dark pt-2 pb-1 text-capitalize"
                  :class="!getContent(event).children || getContent(event).waiting_for_mail || getContent(event).mail_sent ? 'btn-secondary' : 'btn-green'"
                  :disabled="!getContent(event).children || getContent(event).waiting_for_mail"
                >
                  <span v-if="!getContent(event).mail_sent">{{ft('globals.send')}}</span>
                  <i v-else class="far fa-check"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="h6">{{ft('globals.past_events')}}</div>
    <div class="table-responsive">
      <table class="table table-borderless">
        <tbody>
          <tr v-for="event in pastEvents" :key="getContent(event).id">
            <td>
              <div class="d-flex flex-column justify-content-center bg-primary h-100">
                <strong>{{getContent(event).title}}</strong>
                <div>
                  {{getSite(event).title}} 
                  <span v-if="getContent(event).location" v-html="`(${getContent(event).location})`"></span>
                  <span v-else="!!getSite(event).description" v-html="`(${getSite(event).description})`"></span>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-end bg-primary h-100">
                {{getEventDate(event)}}
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-end bg-primary h-100 manage-container">
                <a :href="`${content.people_update_link.url}?ugc_content_name=${event}`" :target="getTarget(content.update_link)" class="btn btn-secondary p-2 border-0 d-flex justify-content-center align-items-center">
                  <i class="far fa-user-friends"></i>
                </a>
                <a :href="`${content.materials_link.url}?ugc_content_name=${event}`" :target="getTarget(content.update_link)" class="btn btn-secondary p-2 ml-2 border-0 d-flex justify-content-center align-items-center">
                  <i class="far fa-file-upload"></i>
                </a>
                <a :href="`${content.update_link.url}?ugc_content_name=${event}`" :target="getTarget(content.update_link)" class="btn btn-secondary p-2 ml-2 border-0 d-flex justify-content-center align-items-center">
                  <i class="far fa-edit"></i>
                </a>
                <button class="btn btn-secondary p-2 border-0 ml-2 d-flex justify-content-center align-items-center" @click="showDeleteModal(event)">
                  <i class="far fa-trash"></i>
                </button>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center bg-primary h-100">
                <button
                  :title="(!getContent(event).children || getContent(event).waiting_for_mail) ? ft('globals.cannot_send') : ft('globals.send')"
                  @click="sendMails(getContent(event))"
                  class="btn w-100 border-0 text-dark pt-2 pb-1 text-capitalize"
                  :class="!getContent(event).children || getContent(event).waiting_for_mail || getContent(event).mail_sent ? 'btn-secondary' : 'btn-green'"
                  :disabled="!getContent(event).children || getContent(event).waiting_for_mail"
                >
                  <span v-if="!getContent(event).mail_sent">{{ft('globals.send')}}</span>
                  <span v-else><i class="far fa-check mr-2"></i>{{ft('globals.resend')}}</span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div ref="deleteModal" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-0 mt-3 mr-3 p-0 d-flex justify-content-end">
            <button type="button" class="btn d-flex justify-content-center align-items-center btn-close p-2" @click="hideDeleteModal">
              <span aria-hidden="true">
                <i class="fal fa-times"></i>
              </span>
            </button>
          </div>
          <div class="modal-body text-center">
            <h3>{{ft('globals.delete_title')}}</h3>
            <div v-if="toDelete" v-html="`${ft('globals.delete_description')}<br/> “${getContent(toDelete).title}”?`"></div>
          </div>
          <div class="modal-footer border-0 justify-content-center p-4">
            <button type="button" class="btn btn-dark" @click="deleteVisit">{{ft('globals.delete')}}</button>
            <button type="button" class="btn btn-secondary" @click="hideDeleteModal">{{ft('globals.cancel')}}</button>
          </div>
        </div>
      </div>
    </div>
    <modal-static-progress ref="mailProgress" :progress="parseInt(mail.progress)"></modal-static-progress>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut faster">
      <div v-if="filtersModalOpened" ref="filters-modal" class="content-modal" fullscreen-modal>
        <button type="button" class="btn d-flex justify-content-center align-items-center btn-close p-2" @click="hideFilterModal">
          <i class="fal fa-times"></i>
        </button>
        <div class="content-modal-container w-100 h-100vh-header">
          <div class="container h-100 d-flex flex-column justify-content-between text-center">
            <div class="row justify-content-center">
              <div class="col-12 col-md-8 col-lg-6">
                <h1 class="text-center mb-4">{{ft('globals.filter_by')}}</h1>
                <div class="row mx-0 text-left">
                  <div class="form-group col-12">
                    <label>{{ft("globals.visit_type")}}</label>
                    <div class="custom-select-outer">
                      <select class="custom-select" v-model="filters.visitType">
                        <option :value="undefined" disabled selected>{{ft("globals.select_visit_type")}}</option>
                        <option
                          v-for="(option, optionIndex) in (pageModel.decorators['visit-type'] || [])"
                          :key="`option-${optionIndex}`"
                          :value="option.name"
                          :selected="option == filters.visitType"
                        >{{option.title}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-12">
                    <label>{{ft("globals.place")}}</label>
                    <div class="custom-select-outer">
                      <select class="custom-select" v-model="filters.site">
                        <option :value="undefined" disabled selected>{{ft("globals.select_locality")}}</option>
                        <option
                          v-for="(option, optionIndex) in (pageModel.decorators.site || [])"
                          :key="`option-${optionIndex}`"
                          :value="option.name"
                          :selected="option == filters.site"
                        >{{option.title}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-6">
                    <label>{{ft("globals.starting_date")}}</label>
                    <input class="form-control" type="date" v-model="filters.datesInterval[0]"/>
                  </div>
                  <div class="form-group col-6">
                    <label>{{ft("globals.ending_date")}}</label>
                    <input class="form-control" type="date" v-model="filters.datesInterval[1]"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mb-5">
              <button type="button" class="btn btn-dark" @click="hideFilterModal">{{ft("globals.apply")}}</button>
              <button type="button" class="btn btn-secondary ml-2" @click="resetFilters(['site', 'visitType', 'datesInterval.0', 'datesInterval.1'])">{{ft("globals.reset")}}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../stylesheets/page/visite_mulino_events_table.scss";
@import "../../stylesheets/page/visite_mulino_fullscreen_modal.scss";

[page-all-events-component] {
  min-height: calc(100vh - #{$header-height});

  .btn-close {
    line-height: 1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .75rem;
    background-color: #DCCEC365;
  }

  .alert {
    position: fixed;
    top: 1rem;
    display: inline;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
  }

  .manage-container {
    .btn {
      width: 2rem;
      height: 2rem;
      border-radius:.75rem;
    }
  }

  .alert {
    border: none;
  }

  .alert-success {
    background-color: rgba(77, 171, 117, .3);
    color: #409D63;
  }

  td:first-child {
    width: 50%;
  }
  td:nth-child(2) {
    width: 180px;
  }
  td, td button {
    white-space: nowrap;
  }
}
</style>