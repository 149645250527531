<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';
import { pick, uniqBy, omit } from 'lodash';

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      users: [],
      events: [],
      activeSection: "events",
      enteringSection: "",
      selectedUser: undefined,
      filtersModalOpened: false,
      eventsFilters: {
        site: undefined,
        visitType: undefined,
        datesInterval: [undefined, undefined]
      },
      usersFilters: {
        name: undefined,
        email: undefined,
        businessName: undefined,
        visit: undefined,
      }
    }
  },
  mounted() {this.getInfo()},
  methods: {
    getInfo() {
      Fandom.ajax({
        url: `/simple-form/visits/report`,
        method: 'GET',
        success: data => {
          console.log(data);
          Vue.set(this, 'users', data.users);
          Vue.set(this, 'events', data.visits);
        },
        error: err => {
          console.error(err);
        }
      })
    },
    selectionClickHandler(choice) {
      this.activeSection = "";
      this.enteringSection = choice;
    },
    showFilterModal() {
      this.filtersModalOpened = true;
      Vue.nextTick(() => {
        disableBodyScroll(this.$refs["filters-modal"]);
      });
    },
    hideFilterModal() {
      clearAllBodyScrollLocks();
      this.filtersModalOpened = false;
    },
    showUserModal(user) {
      const pickProps = Object.keys(user).filter(prop => !this.hiddenUserProperties.includes(prop));
      Vue.set(this, 'selectedUser', pick(user, pickProps));
      Vue.nextTick(() => {
        disableBodyScroll(this.$refs['user-modal']);
      })
    },
    hideUserModal() {
      clearAllBodyScrollLocks();
      Vue.set(this, 'selectedUser', undefined);
    },
    resetFilters(keys = []) {
      const filtersKey = `${this.activeSection}Filters`;
      const keysToReset = keys.length > 0 ? keys : Object.keys(this[filtersKey]);
      keysToReset.forEach(k => {
        let source = undefined;
        if (k.split('.').length > 1) {
          const kArr = k.split('.');
          source = this[filtersKey][kArr[0]];
          k = kArr[1];
        } else {
          source = this[filtersKey];
        }
        if (source && source[k] && source[k] instanceof Array) {
          for(let i in source[k]) {
            Vue.set(source[k], i, undefined);
          }
        } else {
          Vue.set(source, k, undefined);
        }
      });
      this.hideFilterModal();
    },
    showVisitUsers(visit) {
      this.usersFilters.visit = visit;
      this.selectionClickHandler("users");
    }
  },
  computed: {
    hiddenUserProperties() {
      return (this.content.hidden_user_properties || "").split(",");
    },
    halfWidthUserProperties() {
      return (this.content.half_width_user_properies || "").split(",");
    },
    visitTypes() {
      const allTypes = this.events.map(e => {
        return {label: this.getMLInfo(e.type_title), value: e.type_name}
      });
      return uniqBy(allTypes,'value');
    },
    visitSites() {
      const allSites = this.events.map(e => {
        let siteLabel = this.getMLInfo(e.site_title);
        if (e.site_description)
          siteLabel += ` (${this.getMLInfo(e.site_description)})`;
        return {
          label: siteLabel,
          value: e.site_name
        }
      });
      return uniqBy(allSites,'value');
    },
    filteredEvents() {
      return this.events
        .filter(c => !!c.valid_from && !c.general_access_instance)
        .filter(c => !this.eventsFilters.site || c.site_name == this.eventsFilters.site)
        .filter(c => !this.eventsFilters.visitType || c.type_name == this.eventsFilters.visitType)
        .filter(c => !this.eventsFilters.datesInterval[0] || c.date.split("T")[0] >= this.eventsFilters.datesInterval[0])
        .filter(c => !this.eventsFilters.datesInterval[1] || c.date.split("T")[0] <= this.eventsFilters.datesInterval[1])
        .sort((a,b) => a.date === b.date ? 0 : a.date > b.date ? -1 : 1);
    },
    filteredUsers() {
      const users = this.users
        .filter(u => !this.usersFilters.name || `${u.first_name} ${u.last_name}`.toLowerCase().includes(this.usersFilters.name.toLowerCase()))
        .filter(u => !this.usersFilters.email || u.email.toLowerCase().includes(this.usersFilters.email.toLowerCase()))
        .filter(u => !this.usersFilters.visit || u.visit_name === this.usersFilters.visit)
        .filter(u => !this.usersFilters.businessName || u.business_name.toLowerCase().includes(this.usersFilters.businessName.toLowerCase()))
        .sort((a,b) => `${a.first_name} ${a.last_name}` === `${b.first_name} ${b.last_name}` ? 0 : `${a.first_name} ${a.last_name}` > `${b.first_name} ${b.last_name}` ? 1 : -1);
      return uniqBy(users, 'email').map(u => omit(u, ["visit_name"]));
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container py-4" :class="contentType" admin-page-report-component>
    <div class="container py-5">
      <div class="row justify-content-center h-100">
        <div class="col-12 col-lg-6">
          <h1 class="font-weight-bold text-center" v-if="content.title">{{content.title}}</h1>
          <p class="mb-4 d-block text-center" v-if="content.description" v-html="content.description"></p>
          <div class="mb-4 mb-lg-5 d-flex justify-content-center">
            <button
              id="events-button"
              @click="selectionClickHandler('events')"
              class="btn d-flex align-items-center justify-content-between cursor-pointer"
              :class="`btn-${activeSection === 'events' ? 'primary' : 'dark-brown border-0 text-dark'}`"
              :disabled="activeSection === 'events'"
            >
              {{ft('globals.events')}}
              <input :checked="activeSection === 'events'" type="radio" id="events-radio" value="events"/> 
            </button>
            <button
              id="users-button"
              @click="selectionClickHandler('users')"
              class="btn d-flex align-items-center justify-content-between ml-3 cursor-pointer"
              :class="`btn-${activeSection === 'users' ? 'primary' : 'dark-brown border-0 text-dark'}`"
              :disabled="activeSection === 'users'"
            >
              {{ft('globals.people')}}
              <input :checked="activeSection === 'users'" type="radio" id="users-radio" value="users"/> 
            </button>
          </div>
        </div>
        <div class="col-12 d-flex">
          <button class="btn btn-dark-brown text-dark border-0 mr-2" @click="showFilterModal">
            <span>{{ft('globals.filter')}}</span>
            <i class="far fa-sliders-h ml-3"></i>
          </button>
          <a class="btn btn-dark-brown text-dark border-0" href="/simple-form/export">
            <span>{{ft('globals.export')}}</span>
            <i class="far fa-download ml-3"></i>
          </a>
        </div>
        <div class="col-12">
          <transition @after-leave="activeSection=enteringSection" enter-active-class="animated fadeInLeft faster" leave-active-class="animated fadeOutLeft faster">
            <div v-if="activeSection === 'events'">
              <div class="d-flex flex-wrap pt-3">
                <div v-if="eventsFilters.site" class="px-2 pt-1 bg-primary rounded-pill mr-2">
                  <span v-html="getMLInfo(visitSites.find(s => s.value === eventsFilters.site).label)"/> <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['site'])"></i>
                </div>
                <div v-if="eventsFilters.visitType" class="px-2 pt-1 bg-primary rounded-pill mr-2">
                  <span v-html="getMLInfo(visitTypes.find(t => t.value === eventsFilters.visitType).label)"/> <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['visitType'])"></i>
                </div>
                <div v-if="eventsFilters.datesInterval[0]" class="px-2 pt-1 bg-primary rounded-pill mr-2">
                  {{ft('globals.after')}} {{getDate(eventsFilters.datesInterval[0])}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['datesInterval.0'])"></i>
                </div>
                <div v-if="eventsFilters.datesInterval[1]" class="px-2 pt-1 bg-primary rounded-pill">
                  {{ft('globals.before')}} {{getDate(eventsFilters.datesInterval[1])}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['datesInterval.1'])"></i>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-borderless table-events">
                  <tbody>
                    <tr v-for="event in filteredEvents" :key="event.name">
                      <td>
                        <div class="d-flex flex-column justify-content-center bg-primary h-100">
                          <strong>{{getMLInfo(event.title)}}</strong>
                          <div>
                            {{getMLInfo(event.site_title)}} <span v-if="!!event.site_description" v-html="`(${getMLInfo(event.site_description)})`"></span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center justify-content-end bg-primary h-100">
                          {{getEventDate(event)}}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center justify-content-end bg-primary h-100">
                          <button class="btn btn-secondary font-weight-normal pt-2 pb-1 border-0" :disabled="!event.number_of_users" @click="showVisitUsers(event.name)">
                            <span class="pr-3">{{event.number_of_users}}</span> <i class="far fa-user-friends"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </transition>
          <transition @after-leave="activeSection=enteringSection" enter-active-class="animated fadeInRight faster" leave-active-class="animated fadeOutRight faster">
            <div v-if="activeSection === 'users'">
              <div class="d-flex flex-wrap py-3">
                <div v-if="usersFilters.name" class="px-2 pt-1 bg-primary rounded-pill mr-2">
                  <span v-html="ft('globals.name_or_surname')"/>: {{usersFilters.name}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['name'])"></i>
                </div>
                <div v-if="usersFilters.email" class="px-2 pt-1 bg-primary rounded-pill mr-2">
                  <span v-html="ft('globals.email')"/>: {{usersFilters.email}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['email'])"></i>
                </div>
                <div v-if="usersFilters.businessName" class="px-2 pt-1 bg-primary rounded-pill mr-2">
                  <span v-html="ft('globals.business_name')"/>: {{usersFilters.businessName}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['businessName'])"></i>
                </div>
                <div v-if="usersFilters.visit" class="px-2 pt-1 bg-primary rounded-pill mr-2">
                  <span v-html="ft('globals.event')"/>: {{filteredEvents.find(e => e.name === usersFilters.visit).title}} <i class="fal fa-times-circle cursor-pointer ml-2" @click="resetFilters(['visit'])"></i>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <tbody>
                    <tr v-for="user in filteredUsers" :key="user.email">
                      <td>
                        <div class="d-flex align-items-center bg-primary h-100">
                          {{user.first_name}} {{user.last_name}}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center bg-primary h-100">
                          {{user.email}}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center bg-primary h-100">
                          {{user.business_name}}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center justify-content-end bg-primary h-100">
                          <button class="btn btn-secondary border-0 d-flex justify-content-center align-items-center pt-2 pb-1" @click="showUserModal(user)">
                            {{ft("globals.see")}}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut faster">
      <div v-if="selectedUser" ref="user-modal" class="content-modal" fullscreen-modal>
        <button type="button" class="btn d-flex justify-content-center align-items-center btn-close p-2" @click="hideUserModal">
          <i class="fal fa-times"></i>
        </button>
        <div class="content-modal-container w-100 h-100vh-header">
          <div class="container h-100 d-flex flex-column justify-content-between text-center">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-8">
                <h1>{{ft('globals.user_details')}}</h1>
                <div class="row">
                  <div v-for="(propValue, propName) in selectedUser" class="col-12 mb-4 text-left" :class="{'col-md-6': halfWidthUserProperties.includes(propName)}">
                    <label :for="propName">{{ft(`globals.user_props.${propName}`)}}</label>
                    <input disabled class="form-control" :value="propValue"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button type="button" class="btn btn-dark my-5 px-5" @click="hideUserModal">{{ft("globals.close")}}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut faster">
      <div v-if="filtersModalOpened" ref="filters-modal" class="content-modal" fullscreen-modal>
        <button type="button" class="btn d-flex justify-content-center align-items-center btn-close p-2" @click="hideFilterModal">
          <i class="fal fa-times"></i>
        </button>
        <div class="content-modal-container w-100 h-100vh-header">
          <div class="container h-100 d-flex flex-column justify-content-between text-center">
            <div class="row justify-content-center">
              <div class="col-12 col-md-8 col-lg-6">
                <h1 class="text-center mb-4">{{ft('globals.filter_by')}}</h1>
                <div v-if="activeSection === 'events'" class="row mx-0 text-left">
                  <div class="form-group col-12">
                    <label>{{ft("globals.visit_type")}}</label>
                    <div class="custom-select-outer">
                      <select class="custom-select" v-model="eventsFilters.visitType">
                        <option :value="undefined" disabled selected>{{ft("globals.select_visit_type")}}</option>
                        <option
                          v-for="(option, optionIndex) in visitTypes"
                          :selected="option == eventsFilters.visitType"
                          :key="`option-${optionIndex}`"
                          :value="option.value"
                          v-html="option.label"></option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-12">
                    <label>{{ft("globals.place")}}</label>
                    <div class="custom-select-outer">
                      <select class="custom-select" v-model="eventsFilters.site">
                        <option :value="undefined" disabled selected>{{ft("globals.select_locality")}}</option>
                        <option
                          v-for="(option, optionIndex) in visitSites"
                          :selected="option == eventsFilters.site"
                          :key="`option-${optionIndex}`"
                          :value="option.value"
                          v-html="option.label"></option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group col-6">
                    <label>{{ft("globals.starting_date")}}</label>
                    <input class="form-control" type="date" v-model="eventsFilters.datesInterval[0]"/>
                  </div>
                  <div class="form-group col-6">
                    <label>{{ft("globals.ending_date")}}</label>
                    <input class="form-control" type="date" v-model="eventsFilters.datesInterval[1]"/>
                  </div>
                </div>
                <div v-else-if="activeSection === 'users'" class="row mx-0 text-left">
                  <div class="form-group col-12">
                    <label>{{ft("globals.name_or_surname")}}</label>
                    <input class="form-control" type="text" :placeholder="ft('globals.name_or_surname_placeholder')" v-model="usersFilters.name"/>
                  </div>
                  <div class="form-group col-12">
                    <label>{{ft("globals.email")}}</label>
                    <input class="form-control" type="text" :placeholder="ft('globals.email_placeholder')" v-model="usersFilters.email"/>
                  </div>
                  <div class="form-group col-12">
                    <label>{{ft("globals.business_name")}}</label>
                    <input class="form-control" type="text" :placeholder="ft('globals.business_name_placeholder')" v-model="usersFilters.businessName"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mb-5">
              <button type="button" class="btn btn-dark" @click="hideFilterModal">{{ft("globals.apply")}}</button>
              <button type="button" class="btn btn-secondary ml-2" @click="resetFilters()">{{ft("globals.reset")}}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../stylesheets/page/visite_mulino_events_table.scss";
@import "../../stylesheets/page/visite_mulino_fullscreen_modal.scss";

[admin-page-report-component] {
  min-height: calc(100vh - #{$header-height});
  input[type=radio] {
    border-radius: 50%;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    transition: 0.2s all linear;
    position: relative;
    margin-left: 40px;
    &:checked {
      border: 5px solid black;
    }
  }

  p {
    display: inline;
  }

  .content-modal {
    input:disabled {
      background-color: #ECE4DE !important;
    }
  }

  .table-events {
    td {
      white-space: nowrap;

      &:first-child {
        width: 70%;
      }
    }
  }
}
</style>