<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash';
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      email: ''
    }
  },
  methods: {
    sendMail() {
      Fandom.ajax({
        url: '/simple-form/users/send_mail',
        data: {
          email: this.email,
          path: get(this.content, ["landing","url"], "/")
        },
        success: (_) => {
          window.location.href = this.thankYouUrl;
        },
        error: (xhr) => {
          console.error(xhr);
          window.location.href = this.thankYouUrl;
        }
      })
    }
  },
  computed: {
  }
};
</script>

<template>
  <div class="container text-center py-5 h-100vh" page-user-home-component>
    <div class="row justify-content-center h-100">
      <div class="col-12 col-lg-6">
        <div v-if="content.image" class="w-100 d-flex justify-content-center mb-4">
          <div class="w-50">
            <img :src="content.image.url" :alt="content.image.alt" class="img-fluid"/>
          </div>
        </div>
        <h1 class="font-weight-bold" v-if="content.title">{{content.title}}</h1>
        <p class="mb-4" v-if="content.description" v-html="content.description"></p>
        <form @submit.prevent="sendMail" action="/simple-form/users/send_mail" method="post" class="text-left">
          <label for="email">{{ft('globals.email')}}</label>
          <input id="email" type="email" name="email" class="form-control" required v-model="email" :placeholder="ft('globals.email_placeholder')"/>
          <div class="w-100 d-flex justify-content-center mt-4">
            <button class="btn px-5 btn-dark" >{{content.button_text || ft('globals.send')}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
