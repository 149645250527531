<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      selected: -1
    }
  },
  methods: {
    getDecorator: Fandom.getDecorator,
    optionClickHandler(groupIndex, optIndex) {
      this.$emit('selected', { content: this.content.options_groups[groupIndex].options[optIndex], groupIndex: groupIndex });
    }
  }
};
</script>

<template>
  <div class="container d-flex flex-column align-items-center justify-content-between pt-3 pb-4" check-in-step-component>
    <div class="text-center">
      <component :is="content.title_tag || 'h2'" :class="content.title_class || 'h2'" v-if="content.title">{{content.title}}</component>
      <div :class="content.description_class" v-html="content.description" v-if="content.description"></div>
    </div>
    <div class="row w-100 justify-content-center">
      <div class="mb-4 mb-md-5 col-12 col-md-8" v-for="(optGroup, groupIndex) in content.options_groups">
        <div class="m-2" v-if="optGroup.title">{{optGroup.title}}</div>
        <div class="d-flex flex-wrap">
          <div v-for="(opt, optIndex) in optGroup.options" :key="`option-${optIndex}`" class="option p-2 p-md-3 cursor-pointer d-flex flex-md-column justify-content-md-between m-2" @click="optionClickHandler(groupIndex, optIndex)">
            <img v-if="opt.image" :src="opt.image.url" class="image" />
            <div class="image position-relative" v-else-if="opt.icon">
              <div class="position-absolute-center d-flex justify-content-center align-items-center bg-secondary">
                <i class="fal" :class="`fa-${opt.icon}`"></i>
              </div>
            </div>
            <div class="pl-2 ml-1 pl-md-0 ml-md-0 d-flex flex-column justify-content-center">
              <div v-if="opt.description && !optGroup.description_category_class" v-html="opt.description"></div>
              <p v-else-if="opt.location" v-html="opt.location"></p>
              <p v-else-if="optGroup.description_category_class" v-html="(getDecorator(opt, optGroup.description_category_class) || {}).title"></p>
              <div class="font-weight-bold" v-if="opt.title">{{opt.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="content.button_text">
      <button class="btn btn-dark-brown text-dark px-4" @click="$emit('prev')">{{content.button_text || 'hide'}}</button>
    </div>
  </div>
</template>

<style lang="scss">
[check-in-step-component] {
  min-height: calc(100vh - 9.25rem);

  .opacity-0 {
    opacity: 0;
    z-index: -1;
  }

  .option {
    width: 12.5rem;
    height: 12.5rem;
    color: #000;
    background-color: $primary;
    border-radius: 2.25rem;
    transition: all .25s;

    &:hover {
      background-color: rgba(#7B4922, .35);
      color: rgba(255,255,255,.5);

      .image {
        opacity: .5;
      }
    }
    
    p {
      font-size: .75rem;
      margin-bottom: 0 !important;
    }

    .image {
      font-size: 2rem;
      overflow: hidden;
      border-radius: 1.5rem;
      background-color: $primary;
      opacity: 1;
      width: 50%;
      transition: opacity .25s;

      &:not(img) {
        padding-bottom: 50%;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-grow: 1;
      height: auto;
      width: auto;
      border-radius: 1rem;

      .image {
        width: 3rem;
        height: 3rem;
        border-radius: .75rem;

        &:not(img) {
          padding-bottom: 0;
        }
      }
    }
  }


}
</style>
