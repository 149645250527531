<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      form: {
        success: false,
        errors: [],
        loading: false,
        data: {},
      },
      formFields: JSON.parse(this.content.form_fields || '{fields:[]}').fields
    }
  },
  props: {
    formUrl: {
      type: String,
      default: "/simple-form/visit"
    }
  },
  mounted() {
    if (this.getParams().ugc_content_name) {
      Fandom.getContents(this.getParams().ugc_content_name, {layout: "system-admin-visit"}, (data) => {
        const mainContent = data.name_to_content[data.main_content_name];
        Vue.set(this.form.data, 'site', mainContent.decorators.site);
        this.formFields.forEach(field => {
          if (mainContent[field.name] && this.shouldShowField(field)) {
            Vue.set(this.form.data, field.name, mainContent[field.name]);
          }
        });
        if (mainContent.template_name != "VisitTypeLaboratory") {
          Vue.set(this.form.data, 'valid_from', mainContent.valid_from.split("T")[0]);
          Vue.set(this.form.data, 'valid_to', mainContent.valid_to.split("T")[0]);
          Vue.delete(this.form.data, 'date');
        } else {
          Vue.set(this.form.data, 'date', mainContent.valid_from.split("T")[0]);
          Vue.delete(this.form.data, 'valid_from');
          Vue.delete(this.form.data, 'valid_to');
        }
      })
    }
  },
  methods: {
    shouldShowField(field) {
      if (!field.show_if) return true;
      else {
        const fieldName = field.show_if.split(":")[0];
        const fieldValue = field.show_if.split(":")[1];
        return fieldValue === this.form.data[fieldName];
      }
    },
    getOptions(field) {
      if (field.options) {
        return typeof field.options === 'string' ? field.options.split(',') : field.options;
      } else {
        const efTagName = field.options_tag_ef || `${field.name}_tag`;
        return this.getContent(this.content[efTagName]).children.map(c => {
          return {label: this.getContent(c).title, value: c};
        });
      }
    },
    submitForm() {
      if (!this.form.loading) {
        this.form.success = false;
        this.form.loading = true;
        this.form.errors = null;
        let submitForm = () => {
          Fandom.ajax(
            {
              async: true,
              processData: false,
              contentType: false,
              method: "POST",
              url: this.formUrl,
              data: this.getFormData(),
              success: (data) => {
                this.form.success = data.success;
                if (this.form.success) {
                  this.form.progress = 0;
                  if (Fandom.exists(this.content.thank_you_page)) {
                    this.successMessage = null;
                    window.location.href = `${Fandom.applyContextToUrl(this.thankYouUrl)}?success_message=${this.content.success_message || this.ft('globals.messages.visit_create.success')}`;
                  }
                } else {
                  if (Fandom.exists(this.content.thank_you_page)) {
                    window.location.href = `${Fandom.applyContextToUrl(this.thankYouUrl)}?error_message=${data.errors.join(", ")}`;
                  } else {
                    this.form.errors = data.errors;
                  }
                }
              },
              error: (err) => {
                console.log(err);
                this.form.errors = err.responseJSON.errors;
              }
            },
            () => {
              this.progress = null;
              this.form.loading = false;
            }
          );
        }
        submitForm();
      }
    },
    getFormData() {
      const formData = new FormData();
      formData.append("content_id", this.containerContent.id);
      formData.append("interaction_id", this.content.interaction.id);
      if (this.getParams().ugc_content_name)
        formData.append("ugc_content_name", this.getParams().ugc_content_name);
      this.formFields.forEach(field => {
        const formDataValue = this.form.data[field.name];
        if (!!formDataValue) {
          formData.append(`user_upload_interaction[${field.name}]`, formDataValue);
        }
      });
      return formData;
    },
    getOptionValue(field, option) {
      const value = this.getMLInfo(option.value) || this.getMLInfo(option);
      if (field.use_as_template) {
        return value.split("-").map(v => {
          return v.charAt(0).toUpperCase() + v.slice(1)
        }).join("")
      }
      return value;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container py-4" :class="contentType" admin-page-event-component>
    <div class="container py-5">
      <div class="row justify-content-center h-100">
        <div class="col-12 col-lg-6">
          <div v-if="form.errors && form.errors.length > 0" class="alert alert-danger pb-1 pt-2 small font-weight-bold mb-4" role="alert">
            <i class="far fa-times-octagon mr-2"></i> <b>Attenzione: </b>{{form.errors.join(",")}}
          </div>
          <h1 class="font-weight-bold text-center" v-if="content.title">{{content.title}}</h1>
          <p class="mb-4 text-center" v-if="content.description" v-html="content.description"></p>
          
          <form ref="form" @submit.prevent="submitForm" :action="formUrl" method="post" enctype="multipart/form-data">
            <div v-if="formFields && formFields.length > 0">
              <div class="row">
                <div class="form-group" v-for="field in formFields.filter(f => shouldShowField(f))" :key="field.name" :class="field.small ? 'col-6' : 'col-12'" >
                  <label :for="field.name">{{getMLInfo(field.title)}} <span v-if="field.required">*</span></label>
                  <div v-if="field.type == 'dropdown'" class="custom-select-outer">
                    <select :id="field.name" :name="field.name" class="custom-select" :required="field.required" v-model="form.data[field.name]">
                      <option :value="undefined" disabled selected>{{getMLInfo(field.placeholder)}}</option>
                      <option
                        v-for="(option, optionIndex) in getOptions(field)"
                        :key="`option-${optionIndex}`"
                        :value="getOptionValue(field, option)"
                        :selected="field.value == getMLInfo(option.value)"
                        :disabled="field.disabled"
                      >{{getMLInfo(option.label) || getMLInfo(option)}}</option>
                    </select>
                  </div>
                  <input
                    v-else
                    :id="field.name"
                    :type="field.type"
                    :name="field.name"
                    class="form-control"
                    :pattern="field.pattern"
                    :required="field.required"
                    :disabled="field.disabled"
                    v-model="form.data[field.name]"
                    :placeholder="getMLInfo(field.placeholder)"
                  />
                </div>
              </div>
              <div class="w-100 d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-dark px-5" :disabled="form.loading">{{content.button_text || ft('globals.ok')}}</button>
                <button  @click="goBack" class="btn btn-dark-brown text-dark border-0 px-5 ml-3">{{ft('globals.cancel')}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[admin-page-event-component] {
  min-height: calc(100vh - #{$header-height});
}
</style>