<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from 'src/modules/interactions_module.js';
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, interactionMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      openSurvey: false
    }
  },
  methods: {
    downloadInteractionHandler(document) {
      let attachment = document.attachment;
      this.updateDownloadInteraction(this.containerContent, document, document.interaction, () => {}, attachment.url, true);
    },
  },
  computed: {
    date() {
      if (!this.visit.valid_from) return undefined;
      else return this.getDate(this.visit.valid_from)
    },
    site() {
      return this.pageModel.decorators["site"][this.content.decorators["site"]];
    },
    visit() {
      return this.pageModel.decorators["visit"][this.content.decorators["visit"]];
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container py-4" :class="contentType" page-event-materials-component event-materials>
    <div class="row justify-content-center h-100">
      <div class="col-12 col-lg-6">
        <div class="text-center mb-4">
          <h3 v-if="content.title">{{content.title}}</h3>
          <div>
            {{date}}, {{site.title}}
            <span v-if="!!site.description" v-html="`(${site.description})`"></span>
          </div>
        </div>
        <div class="section-title" v-if="(content.documents || []).length > 0">{{ft('globals.documents')}}</div>
        <div v-for="doc in content.documents" :key="doc.$id" class="my-2 py-1">
          <div class="doc-container p-2 bg-primary cursor-pointer d-flex" @click="downloadInteractionHandler(doc)">
            <div class="icon bg-secondary d-flex justify-content-center align-items-center">
              <i class="far fa-book"></i>
            </div>
            <div class="text pl-2 ml-1 d-flex flex-column justify-content-center">
              <div class="text-uppercase small">{{ft('globals.document')}}</div>
              <div class="font-weight-bold">{{doc.title}}</div>
            </div>
          </div>
        </div>

        <div class="section-title">{{ft('globals.recipes')}}</div>
        <div v-for="rec in content.recipes" :key="rec.$id" class="my-2 py-1">
          <a :href="rec.url.url" target="_blank" class="rec-container p-2 bg-primary d-flex deco-none">
            <div class="thumbnail bg-cover-center" v-if="rec.thumbnail" :style="{'background-image': `url(${rec.thumbnail.url})`}"></div>
            <div class="text text-dark pl-2 ml-1 d-flex flex-column justify-content-center">
              <div class="text-uppercase small">{{ft('globals.recipe')}}</div>
              <div class="font-weight-bold">{{rec.title}}</div>
            </div>
          </a>
        </div>
        <div class="mb-4 mt-5" v-if="content.survey && !isInteractionDone(containerContent, content.survey.interaction)">
          <div class="banner bg-white p-4 text-center">
            <h2>{{content.survey.title}}</h2>
            <div v-html="content.survey.description"></div>
            <button @click="openSurvey = true" class="btn btn-dark mt-4">{{content.survey.button_text}}</button>
          </div>
        </div>
        <div class="mb-4 mt-5" v-else-if="content.survey && isInteractionDone(containerContent, content.survey.interaction)">
          <div class="banner bg-white p-4 text-center">
            <h2>{{ft('globals.survey_already_done.title')}}</h2>
            <div v-html="ft('globals.survey_already_done.description')"></div>
          </div>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated fadeInUp faster" leave-active-class="animated fadeOutDown faster">
      <modal-survey v-if="openSurvey" @close="openSurvey = false" :content="content.survey" :opt-container-content="containerContent"></modal-survey>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../stylesheets/page/visite_mulino_event_materials.scss";

[page-event-materials-component] {
  min-height: calc(100vh - #{$header-height});

  span > p {
    display: inline;
    margin-bottom: 0;
  }

  .section-title {
    font-size: .875rem;
  }

  a {
    text-decoration: none !important;
  }
}
</style>