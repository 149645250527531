<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      feedback: { show: false },
      feedbackTimeout: null,
      users: [],
      newUser: {},
      form: {
        loading: false
      },
      newUserModalOpened: false,
      activeSection: 'active',
      enteringSection: 'active'
    }
  },
  created() {
    Fandom.ajax({
      url: "/api/v5/admin-users",
      method: "GET",
      success: (data) => {
        Vue.set(this, 'users', data);
      }
    })
  },
  methods: {
    showFeedback(message, success) {
      this.feedback.show = false;
      clearTimeout(this.feedbackTimeout);
      Vue.nextTick(() => {
        Vue.set(this, 'feedback', {
          text: message,
          show: true,
          type: success ? 'success' : 'danger',
          icon: success ? 'fa-check-circle' : 'fa-times-octagon'
        });
        Vue.set(this, 'feedbackTimeout', setTimeout(() => this.feedback.show = false, 5000));
      });
    },
    showNewUserModal() {
      this.newUserModalOpened = true;
      Vue.nextTick(() => {
        disableBodyScroll(this.$refs["new-user-modal"]);
      });
    },
    hideNewUserModal() {
      clearAllBodyScrollLocks();
      this.newUserModalOpened = false;
    },
    createUser() {
      if (!this.form.loading) {
        this.form.loading = true;
        Fandom.ajax({
            url: "/api/v5/admin-users",
            method: "POST",
            data: this.newUser,
            success: (data) => {
              if (!data.errors) {
                Vue.set(this.users, this.users.length, data);
                $(this.$refs["user-password"]).modal("show");
                this.hideNewUserModal();
                Vue.set(this, 'newUser', {});
                this.showFeedback(this.ft('globals.new_user_creation_success'), true);
              } else {
                this.showFeedback(data.errors.join(", "), false);
              }
            },
            error: (data) => {
              this.showFeedback(data.resonseJSON.errors.join(", "), false);
            },
          },
          () => {
            this.form.loading = false;
          }
        )
      }
    },
    activateOrDeactivateUser(user, active) {
      Fandom.ajax({
        url: "/api/v5/admin-users/status",
        method: "POST",
        data: {email: user.email, status: active ? "active" : "inactive"},
        success: (_) => {
          Vue.set(user, 'active', active);
          this.showFeedback(this.ft('globals.user_updated_successfully'), true);
        },
        error: (data) => {
          this.showFeedback(data.resonseJSON.errors.join(", "), false);
        }
      })
    },
    isUserActive(user) {
      return user.active;
    },
    selectionClickHandler(section) {
      this.enteringSection = section;
      this.activeSection = null;
    }
  },
  computed: {
    displayUsers() {
      return this.users.filter(user => this.activeSection == 'active' ? user.active : !user.active);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container py-4" :class="contentType" page-all-events-component>
    <div v-if="feedback.show" class="alert pb-1 pt-2 small font-weight-bold d-flex justify-content-between align-items-center" :class="`alert-${feedback.type}`" role="alert">
      <span class="pr-5"><i class="far mr-2" :class="feedback.icon"></i> {{feedback.text}}</span>
      <i class="far fa-lg fa-times cursor-pointer" @click="feedback.show = false"></i>
    </div>
    <header class="text-center mb-5">
      <h1 class="font-weight-bold text-center" v-if="content.title">{{content.title}}</h1>
      <p class="mb-4 d-block text-center" v-if="content.description" v-html="content.description"></p>
      <div class="mb-4 mb-lg-5 d-flex justify-content-center">
        <button
          id="active-button"
          @click="selectionClickHandler('active')"
          class="btn d-flex align-items-center justify-content-between cursor-pointer"
          :class="`btn-${activeSection === 'active' ? 'primary' : 'dark-brown border-0 text-dark'}`"
          :disabled="activeSection === 'active'"
        >
          {{ft('globals.active_admin_users')}}
          <input :checked="activeSection === 'active'" type="radio" id="active-radio" value="active"/> 
        </button>
        <button
          id="inactive-button"
          @click="selectionClickHandler('inactive')"
          class="btn d-flex align-items-center justify-content-between ml-3 cursor-pointer"
          :class="`btn-${activeSection === 'inactive' ? 'primary' : 'dark-brown border-0 text-dark'}`"
          :disabled="activeSection === 'inactive'"
        >
          {{ft('globals.inactive_admin_users')}}
          <input :checked="activeSection === 'inactive'" type="radio" id="inactive-radio" value="inactive"/> 
        </button>
      </div>
    </header>
    <div class="d-flex justify-content-between flex-wrap mb-3">
      <div class="d-flex">
        <button class="btn btn-dark-brown text-dark border-0 mr-2 mb-2"  @click="showNewUserModal">
          <span>{{ft('globals.new')}}</span>
          <i class="far fa-plus ml-3"></i>
        </button>
      </div>
    </div>
    <transition @after-leave="activeSection=enteringSection" :enter-active-class="`animated fadeIn${activeSection == 'active' ? 'Left' : 'Right'} faster`" :leave-active-class="`animated fadeOut${enteringSection == 'active' ? 'Left' : 'Right'} faster`">
      <div class="table-responsive" v-if="activeSection">
        <table class="table table-borderless">
          <tbody>
            <tr v-for="user in displayUsers" :key="user.email">
              <td>
                <div class="d-flex flex-column justify-content-center bg-primary h-100">
                  {{ user.email }}
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-end bg-primary h-100">
                  {{ user.first_name }}
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-end bg-primary h-100">
                  {{ user.last_name }}
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center justify-content-end bg-primary h-100 manage-container">
                  <button :disabled="user.role == 'super-visits-admin'" @click="activateOrDeactivateUser(user, true)" v-if="!isUserActive(user)" class="btn btn-secondary p-2 border-0 d-flex justify-content-center align-items-center">
                    <i class="far fa-eye"></i>
                  </button>
                  <button :disabled="user.role == 'super-visits-admin'" @click="activateOrDeactivateUser(user, false)" v-if="isUserActive(user)" class="btn btn-secondary p-2 ml-2 border-0 d-flex justify-content-center align-items-center">
                    <i class="far fa-eye-slash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut faster">
      <div v-if="newUserModalOpened" ref="new-user-modal" class="content-modal" fullscreen-modal>
        <button type="button" class="btn d-flex justify-content-center align-items-center btn-close p-2" @click="hideNewUserModal">
          <i class="fal fa-times"></i>
        </button>
        <div class="content-modal-container w-100 h-100vh-header">
          <form ref="form" class="d-flex flex-column" @submit.prevent="createUser($event)">
            <div class="container h-100 d-flex flex-column justify-content-between text-center">
              <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-6">   
                  <h1 class="text-center mb-4">{{ft('globals.new_user')}}</h1>
                  <div class="row mx-0 text-left">
                    <div class="form-group col-12">
                      <label for="email">{{ ft('globals.user_props.email') }}</label>
                      <input id="email" type="email" name="email" class="form-control" required v-model="newUser.email"/>
                    </div>
                    <div class="form-group col-6">
                      <label for="email">{{ ft('globals.user_props.first_name') }}</label>
                      <input id="firstName" type="text" name="first-name" class="form-control" required v-model="newUser.first_name"/>
                    </div>
                    <div class="form-group col-6">
                      <label for="email">{{ ft('globals.user_props.last_name') }}</label>
                      <input id="lastName" type="text" name="last-name" class="form-control" required v-model="newUser.last_name"/>
                    </div>
                    <div class="form-group col-12">
                      <label>{{ft("globals.user_props.company_role")}}</label>
                      <div class="custom-select-outer">
                        <select class="custom-select" required v-model="newUser.role">
                          <option :value="undefined" disabled selected>{{ft("globals.select_role")}}</option>
                          <option value="super-visits-admin">{{ ft("globals.role_super_administrator") }}</option>
                          <option value="visits-admin">{{ ft("globals.role_administrator") }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center mb-5">
                <button type="submit" class="btn btn-dark" :disabled="form.loading">{{ft("globals.apply")}}</button>
                <button type="button" class="btn btn-secondary ml-2" @click="hideNewUserModal">{{ft("globals.cancel")}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </transition>

    <div class="modal fade" ref="user-password" tabindex="-1" aria-hidden="true" modal-static-progress-component>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center p-4">
            <p>{{ft("globals.password")}}</p>
            <p class="h2 mb-0">{{users[users.length - 1].password}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ft("globals.close")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../stylesheets/page/visite_mulino_events_table.scss";
@import "../../stylesheets/page/visite_mulino_fullscreen_modal.scss";

[page-all-events-component] {
  min-height: calc(100vh - #{$header-height});

  .btn-close {
    line-height: 1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .75rem;
    background-color: #DCCEC365;
  }

  .alert {
    position: fixed;
    top: 1rem;
    display: inline;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2010 !important;
  }

  .manage-container {
    .btn {
      width: 2rem;
      height: 2rem;
      border-radius:.75rem;
    }
  }

  .alert {
    border: none;
  }

  .alert-success {
    background-color: rgba(77, 171, 117, .3);
    color: #409D63;
  }

  input[type=radio] {
    border-radius: 50%;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #000;
    transition: 0.2s all linear;
    position: relative;
    margin-left: 40px;
    &:checked {
      border: 5px solid black;
    }
  }

  td:first-child {
    width: 50%;
  }
  td:nth-child(2) {
    width: 180px;
  }
  td, td button {
    white-space: nowrap;
  }
}
</style>