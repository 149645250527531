<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      partecipants: [],
      pendingUsers: [],
      visitName: null
    }
  },
  mounted() {
    if (this.getParams().ugc_content_name) {
      this.visitName = this.getParams().ugc_content_name;
      this.getUsers();
    } else {
      throw "Query string param 'ugc_content_name' must be provided";
    }
  },
  computed: {
    disabled() {
      return !(this.partecipants.length + this.partecipants.length);
    }
  },
  methods: {
    getUsers() {
      Fandom.ajax({
        url: `/simple-form/visit/${this.visitName}/users`,
        method: 'GET',
        success: data => {
          Vue.set(this, 'partecipants', data.partecipants);
          Vue.set(this, 'pendingUsers', data.pending_users || []);
        },
        error: err => {
          console.error(err);
        }
      })
    },
    updateUsers() {
      Fandom.ajax({
        url: `/simple-form/visit/${this.visitName}/users`,
        method: 'POST',
        data: {
          partecipants: [...this.partecipants],
          pending_users: [...this.pendingUsers]
        },
        success: _ => {
          if (this.thankYouUrl) {
            window.location.href = `${Fandom.applyContextToUrl(this.thankYouUrl)}?success_message=${this.content.success_message || this.ft('globals.messages.people_update.success')}`;
          }
        },
        error: err => {
          if (this.thankYouUrl) {
            window.location.href = `${Fandom.applyContextToUrl(this.thankYouUrl)}?error_message=${err.responseJSON.errors.join(", ") || this.ft('globals.messages.people_update.general_error')}`;
          }
        }
      })
    },
    moveInPending(partecipant) {
      const idx = this.partecipants.findIndex(p => p.id === partecipant.id);
      Vue.set(this, 'partecipants', [...this.partecipants.slice(0,idx), ...this.partecipants.slice(idx+1)]);
      Vue.set(this.pendingUsers, this.pendingUsers.length, partecipant);
    },
    addPendingUser(partecipant) {
      const idx = this.pendingUsers.findIndex(p => p.id === partecipant.id);
      Vue.set(this, 'pendingUsers', [...this.pendingUsers.slice(0,idx), ...this.pendingUsers.slice(idx+1)]);
      Vue.set(this.partecipants, this.partecipants.length, partecipant);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container py-4" :class="contentType" admin-page-people-component>
    <div class="container py-5">
      <div class="row justify-content-center h-100">
        <div class="col-12 col-lg-6">
          <h1 class="font-weight-bold text-center" v-if="content.title">{{content.title}}</h1>
          <p class="mb-4 text-center" v-if="content.description" v-html="content.description"></p>
          <div class="py-4">
            <div>{{ft('globals.partecipants')}}</div>
            <div class="small" v-if="partecipants.length === 0">{{ft('globals.no_partecipants')}}</div>
            <div class="d-flex flex-wrap">
              <div v-for="p in partecipants" :key="p.id" class="px-2 pt-1 bg-primary rounded-pill mr-2 my-2">
                {{p.first_name}} {{p.last_name}}<i class="fal fa-times-circle cursor-pointer ml-2" @click="moveInPending(p)"></i>
              </div>
            </div>
          </div>
          <div class="pt-2 pb-4">
            <div>{{ft('globals.pending_users')}}</div>
            <div class="small" v-if="pendingUsers.length === 0">{{ft('globals.no_pending_users')}}</div>
            <div class="d-flex flex-wrap pb-4">
              <div v-for="pu in pendingUsers" :key="pu.id" class="px-2 pt-1 bg-primary rounded-pill mr-2 my-2">
                {{pu.first_name}} {{pu.last_name}}<i class="fal fa-plus-circle cursor-pointer ml-2" @click="addPendingUser(pu)"></i>
              </div>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-center mt-4">
            <button @click="updateUsers" class="btn btn-dark px-5" :disabled="disabled">{{content.button_text || ft('globals.ok')}}</button>
            <button @click="goBack" class="btn btn-dark-brown text-dark border-0 px-5 ml-3">{{ft('globals.cancel')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[admin-page-people-component] {
  min-height: calc(100vh - #{$header-height});

  .custom-control {
    padding-right: 2rem !important;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    right: -1.5rem;
    left: auto;
    top: 2px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $dark;
    background-color: $light;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>")
  }
}
</style>