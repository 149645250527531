<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';
const DEFAULT_BACKGROUND_COLOR = "#c9b3a2";

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  props: {
    initData: {
      type: Object,
      default: () => { return {} }
    },
    errors: {
      type: Array,
      default: () => { return [] }
    }
  },
  data() {
    return {
      formData: { },
      fieldToError: {}
    }
  },
  created() {
    if (!globalState.pageModel?.user?.role) {
      this.formFields.forEach(f => {
        Vue.set(this.formData, f.name, this.initData[f.name]);
      })
    }
  },
  methods: {
    mayCheckEqualValues(field) {
      if (!!field.match_with) {
        if (this.formData[field.name] === this.formData[field.match_with]) {
          Vue.set(this.fieldToError, field.name, null);
        } else {
          const matchingField = this.formFields.find(f => f.name === field.match_with);
          Vue.set(this.fieldToError, field.name, `${this.ft('globals.field_must_match_with')} ${this.getMLInfo(matchingField.label)}`);
        }
      }
      const matchingField = this.formFields.find(f => f.match_with === field.name);
      if (matchingField) {
        if (this.formData[matchingField.name] === this.formData[field.name]) {
          Vue.set(this.fieldToError, matchingField.name, null);
        } else {
          Vue.set(this.fieldToError, matchingField.name, `${this.ft('globals.field_must_match_with')} ${this.getMLInfo(field.label)}`);
        }
      }
    }
  },
  computed: {
    formFields() {
      const fields = JSON.parse(this.content.form_fields).fields || [];
      return fields.filter(f => {
        if (!!f.show_if) {
          const key = f.show_if.split(":")[0];
          let value = f.show_if.split(":")[1];
          if (value.toLowerCase() === "false") value = false;
          else if (value.toLowerCase() === "true") value = true;
          return this.formData[key] === value;
        } else {
          return true;
        }
      });
    },
    style() {
      return {
        'background-color': this.content.background_color || DEFAULT_BACKGROUND_COLOR,
        'color': this.content.background_color ? '#fff' : '#000',
        '--min-height': this.content.background_color ? '100vh' : 'calc(100vh - .3rem)'
      }
    },
    showSubmitButton() {
      return !this.formFields.find(f => f.type === 'selection');
    },
    isSubmitButtonDisabled() {
      return Object.values(this.fieldToError).some(f => !!f);
    }
  }
};
</script>

<template>
  <div :style="style">
    <div class="container text-center py-5" partial-form-component>
      <div class="row justify-content-center h-100">
        <div class="col-12 col-lg-6">
          <div v-if="content.image" class="w-100 d-flex justify-content-center mb-4">
            <div class="w-75">
              <img :src="content.image.url" :alt="content.image.alt" class="img-fluid"/>
            </div>
          </div>
          <h1 class="font-weight-bold" v-if="containerContent.title || content.title">{{containerContent.title || content.title}}</h1>
          <p class="mb-4" v-if="content.description" v-html="content.description"></p>

          <form-feedback v-if="(errors || []).length > 0" :errors="errors" :success="false"></form-feedback>
          <div v-if="content.disclaimer" class="disclaimer text-left my-4 p-3" v-html="content.disclaimer"></div>
          <form @submit.prevent="$emit('update', formData)" action="/upload_simple_form" method="post" v-if="formFields && formFields.length > 0">
            <div class="row">
              <div class="form-group" v-for="field in formFields" :key="field.name" :class="field.small ? 'col-6' : 'col-12'" >
                <label class="text-left w-100 mb-1" :for="field.name" v-if="!['checkbox'].includes(field.type) && field.label">
                  {{getMLInfo(field.label)}} <span v-if="field.required">*</span>
                </label>
                <small class="text-left w-100 d-block text-danger" v-if="fieldToError[field.name]">{{ fieldToError[field.name] }}</small>
                <div v-if="field.type == 'dropdown'" class="custom-select-outer">
                  <select :id="field.name" :name="field.name" class="custom-select" :required="field.required" v-model="formData[field.name]">
                    <option :value="undefined" disabled selected>{{getMLInfo(field.placeholder)}}</option>
                    <option
                      v-for="(option, optionIndex) in (typeof field.options === 'string' ? field.options.split(',') : field.options)"
                      :key="`option-${optionIndex}`"
                      :value="getMLInfo(option.value) || getMLInfo(option)"
                      :selected="field.value == getMLInfo(option.value)"
                    >{{getMLInfo(option.label) || getMLInfo(option)}}</option>
                  </select>
                </div>
                <div v-else-if="field.type === 'checkbox'" class="custom-control custom-checkbox text-left">
                  <input
                    type="checkbox"
                    :id="field.name"
                    :name="field.name"
                    :required="field.required"
                    class="custom-control-input"
                    v-model="formData[field.name]"
                  />
                  <label class="custom-control-label text-left" :for="field.name">
                    <span v-html="`${getMLInfo(field.label)} ${field.required ? ' *' : ''}`"></span>
                  </label>
                </div>
                <template v-else-if="field.type === 'selection'">
                  <div v-for="(choice, index) in field.choices" :key="`choice-${index}`">
                    <button
                      class="btn w-100 d-flex align-items-center justify-content-center py-4"
                      :id="`choice-${field.name}-${choice.id}`"
                      @click="formData[field.name] = choice.id"
                      :class="[`mb-${index < (field.choices.length - 1) ? '2' : '0'}`, (choice.button_class || 'btn-dark')]"
                    >{{getMLInfo(choice.value)}} <i v-if="choice.icon" class="fal ml-3" :class="`fa-${choice.icon}`"></i></button>
                  </div>
                </template>
                <input
                  :id="field.name"
                  :type="field.type"
                  :name="field.name"
                  class="form-control"
                  :pattern="field.pattern"
                  :required="field.required"
                  v-model="formData[field.name]"
                  @keyup="mayCheckEqualValues(field)"
                  :placeholder="getMLInfo(field.placeholder)"
                  v-else
                />
              </div>
            </div>
            <div class="my-4 text-left small" v-if="showSubmitButton">* {{ft('globals.mandatory_fields')}}</div>
            <div class="w-100 d-flex justify-content-center mt-4" v-if="showSubmitButton">
              <button :disabled="isSubmitButtonDisabled" class="btn px-5" :class="content.button_class || 'btn-dark'">{{content.button_text || ft('globals.next')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  [partial-form-component] {
    overflow-y: auto;
    min-height: var(--min-height);
    .disclaimer {
      background-color: rgba($primary, .5);
      border-radius: $input-border-radius;
      max-height: 40vh;
      overflow-y: auto;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }
  }

  [form-feedback-component] {
    background-color: white;
    text-align: left;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-radius: 0.75rem;
  }
  
</style>
