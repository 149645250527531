<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    progress: Number
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    start() {
      this.$emit("progress-init", 0);
      $(this.$refs["static-backdrop"]).modal("show");
    },
    stop() {
      $(this.$refs["static-backdrop"]).modal("hide");
    },
  }
};
</script>

<template>
  <div class="modal fade" ref="static-backdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-hidden="true" modal-static-progress-component>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center p-5">
          <h5 class="modal-title">{{ft('globals.loading')}}</h5>
          <p>{{ft('globals.wait_to_complete')}}</p>
          <div class="progress">
            <div class="progress-bar" role="progressbar" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" :style="{ width: progress + '%' }">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>