<script>
import { get } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { multiLangMixin } from "../../modules/visite_mulino_mixins_module.js";


export default {
  mixins: [fandomBasicMixin, multiLangMixin],
  data() {
    return {
      langModalOpened: false,
      selectedLang: undefined
    }
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    showLangModal() {
      this.langModalOpened = true;
      Vue.nextTick(() => {
        disableBodyScroll(this.$refs["lang-modal"]);
      });
      return this.langModalOpened;
    },
    hideLangModal() {
      clearAllBodyScrollLocks();
      this.langModalOpened = false;
    },
  },
  computed: {
    isAdmin() {
      return globalState.pageModel.user.role == 'admin';
    },
    showSignIn() {
      return !(window.location.pathname === "/users/sign_in");
    },
    mainContent() {
      return get(this.globalState.pageModel.name_to_content, this.globalState.pageModel.main_content_name, {});
    },
  },
};
</script>

<template>
  <div class="w-limiter" navbar>
    <div class="w-100 h-100 px-3 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <a :href="applyContextToUrl('/')" class="btn-small btn-primary d-flex justify-content-center align-items-center">
          <i class="fas fa-home-alt"></i>
        </a>
        <h5 class="ml-2 mb-0" :class="{'d-none d-md-inline': mainContent.help_page}">{{isAdmin ? ft("globals.admin") : content.title}}</h5>
      </div>
      <div class="d-flex align-items-center" v-if="showSignIn">
        <a v-if="globalState.allowedToSeeEasyadminShortcuts && mainContent.help_page" class="mr-4" :href="content.admin_help_page.url" :target="getTarget(content.admin_help_page)">
          <i class="fas fa-life-ring"></i> {{ content.admin_help_page.title }}
        </a>
        <b class="email" v-if="!pageModel.user.anonymous">{{pageModel.user.email}}</b>
        <a href="/users/sign_out" class="btn-small btn-primary d-flex justify-content-center align-items-center ml-2 text-decoration-none">
          <i v-if="isUserRegistered()" class="far fa-sign-out"></i>
          <i v-else class="far fa-sign-in"></i>
        </a>
        <a :href="content.document.url" :target="getTarget(content.document)" v-if="isAdmin && content.document" class="btn btn-small btn-primary d-flex justify-content-center align-items-center ml-2 text-decoration-none">
          <p class="m-0">?</p>
        </a>
        <button @click="showLangModal" class="btn btn-small btn-primary d-flex justify-content-center align-items-center ml-2 text-decoration-none">
          <p class="m-0">{{currentLang}}</p>
        </button>
      </div>
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut faster">
      <div v-if="langModalOpened" ref="lang-modal" class="content-modal" fullscreen-modal>
        <button type="button" class="btn d-flex justify-content-center align-items-center btn-close p-2" @click="hideLangModal">
          <i class="fal fa-times"></i>
        </button>
        <div class="content-modal-container w-100 h-100vh-header">
          <div class="container d-flex flex-column justify-content-between text-center">
            <div class="row justify-content-center">
              <div class="col-12 col-md-8 col-lg-6">
                <h1 class="text-center mb-4">{{ft('globals.select_lang')}}</h1>
                <div class="row mx-0 text-left">
                  <div class="form-group col-12">
                    <a :href="redirectTolang(item)" v-for="(item, index) in ['it', 'en']" :key="index" class="btn btn-primary d-flex justify-content-left align-items-center m-3 p-4 text-decoration-none">
                      <img :src="content.langs[item].url" width="20" class="mr-3">
                      <label class="form-check-label mt-1">{{ft(`globals.langs.${item}`)}}</label>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[navbar] {
  height: $header-height;
  background-color: $secondary;

  input[type="radio"] {
    accent-color: black;
  }

  .form-check-label{
    font-weight: 400;
  }

  .btn {
    line-height: 1;
    border-radius: .75rem;
  }

  .btn-small {
    line-height: 1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .75rem;
  }

  .btn-save {
    color: white !important;
    text-decoration: none;
  }

  .btn-close {
    background-color: #DCCEC365;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: .75rem;
  }
  .alert-success {
    background-color: rgba(77, 171, 117, .3);
    color: #409D63;
  }

  button {
    white-space: nowrap;
  }

  h5 {
    font-size: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .email {
      max-width: 5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
