<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';
import { debounce, get } from "lodash";

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      form: {
        success: false,
        errors: [],
        loading: false,
        data: {},
        progress: 0
      },
      documents: [],
      recipes: [],
      contentsModalOpened: false,
      searchText: '',
      recipesSuggestions: [],
      visitName: null,
      documentsToRemove: [],
      suggestionsTotalNumber: 0
    }
  },
  mounted() {
    if (this.getParams().ugc_content_name) {
      this.visitName = this.getParams().ugc_content_name;
      Fandom.getContents(
        `visit-materials-${this.visitName.split("visit-")[1]}`,
        { },
        (data) => {
          const mainContent = data.name_to_content[data.main_content_name];
          this.formFields.forEach(ff => {
            if (ff.name) {
              Vue.set(this.form.data, ff.name, mainContent[ff.name]);
            }
          });
          Vue.set(this, 'documents', mainContent.documents);
          Vue.set(this, 'recipes', mainContent.recipes);
        }
      )
    } else {
      throw "Query string param 'ugc_content_name' must be provided";
    }
  },
  watch: {
    searchText() {
      this.debouncedSearchContents();
    }
  },
  methods: {
    submitForm() {
      if (!this.form.loading) {
        this.form.success = false;
        this.formLoading = true;
        this.form.errors = null;
        $(this.$refs["static-backdrop"])[0].start();
        let submitForm = () => {
          Fandom.ajax(
            {
              processData: false,
              contentType: false,
              method: "POST",
              url: this.formUrl,
              data: this.getFormData(),
              xhr: () => {
                const xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener("progress", (evt) => {
                  if (evt.lengthComputable) {
                    this.form.progress = evt.loaded / evt.total * 100;
                  }
                }, false);
                return xhr;
              },
              success: (data) => {
                this.form.success = data.success;
                if (this.form.success) {
                  if (Fandom.exists(this.content.thank_you_page)) {
                    window.location.href = `${Fandom.applyContextToUrl(this.thankYouUrl)}?success_message=${this.content.success_message || this.ft('globals.messages.visit_create.success')}`;
                  }
                } else {
                  if (Fandom.exists(this.content.thank_you_page)) {
                    window.location.href = `${Fandom.applyContextToUrl(this.thankYouUrl)}?error_message=${data.errors.join(", ")}`;
                  } else {
                    this.form.errors = data.errors;
                  }
                }
              }
            },
            () => {
              this.form.loading = false;
              $(this.$refs["static-backdrop"])[0].stop();
            }
          );
        }
        submitForm();
      }
    },
    getFormData() {
      const formData = new FormData();
      formData.append("content_id", this.containerContent.id);
      formData.append("interaction_id", this.content.interaction.id);
      if (this.visitName)
        formData.append("ugc_content_name", this.visitName);
      this.documents.forEach((d,i) => {
        formData.append(`documents[${i}]`, d);
      })
      this.documentsToRemove.forEach((d,i) => {
        formData.append(`documents_to_remove[${i}]`, d);
      })
      this.recipes.forEach((r,i) => {
        formData.append(`recipes[${i}][id]`, r.id);
        formData.append(`recipes[${i}][url]`, get(r, ["url","url"], r.url));
        formData.append(`recipes[${i}][title]`, r.title);
        formData.append(`recipes[${i}][thumbnail]`, r.thumbnail.url);
      })
      this.formFields.forEach(field => {
        const formDataValue = this.form.data[field.name];
        if (!!formDataValue) {
          formData.append(`user_upload_interaction[${field.name}]`, formDataValue);
        }
      });
      return formData;
    },
    addDocument(event) {
      Vue.set(this, 'documents', [...this.documents,...event.target.files]);
    },
    showContentsModal() {
      this.contentsModalOpened = true;
      Vue.nextTick(() => {
        disableBodyScroll(this.$refs["modal-contents"]);
      })
    },
    hideContentsModal() {
      this.contentsModalOpened = false;
      Vue.nextTick(() => {
        clearAllBodyScrollLocks();
      })
    },
    addRecipes() {
      Vue.set(this, 'recipes', [...this.recipes,...this.recipesSuggestions.filter(r => r.checked)]);
      this.hideContentsModal();
    },
    removeRecipe(index) {
      Vue.set(this, 'recipes', [...this.recipes.slice(0, index),...this.recipes.slice(index+1)]);
    },
    removeDocument(index) {
      const docId = this.documents[index].$id;
      Vue.set(this, 'documents', [...this.documents.slice(0, index),...this.documents.slice(index+1)]);
      Vue.set(this.documentsToRemove, this.documentsToRemove.length, docId);
    }
  },
  computed: {
    formUrl() {
      return "/simple-form/visit/:visit_name/materials".replace(":visit_name", this.visitName);
    },
    formFields() {
      return JSON.parse(this.content.form_fields || '{fields:[]}').fields
    },
    debouncedSearchContents() {
      return debounce(() => {
        if (this.searchText.length > 2) {
          const params = {
            search_text: this.searchText,
            exclude_content_ids: this.recipes.map(r => r.recipe_id)
          }
          Fandom.ajax({
            url: `/simple-form/recipes`,
            method: "POST",
            data: params,
            success: (data) => {
              Vue.set(this, 'recipesSuggestions', data.recipes);
              this.suggestionsTotalNumber = data.total_results
            }
          })
        }
      }, 300);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container py-4" :class="contentType" admin-page-event-component event-materials>
    <div class="container py-5">
      <div class="row justify-content-center h-100">
        <div class="col-12 col-lg-6">
          <h1 class="font-weight-bold text-center" v-if="content.title">{{content.title}}</h1>
          <p class="mb-4 text-center" v-if="content.description" v-html="content.description"></p>
          
          <form ref="form" @submit.prevent="submitForm" :action="formUrl" method="post" enctype="multipart/form-data">
            <div v-if="formFields && formFields.length > 0">
              <div class="row">
                <div class="form-group mb-4" v-for="field in formFields" :key="field.name" :class="field.small ? 'col-6' : 'col-12'" >
                  <label :for="field.name">{{getMLInfo(field.title)}} <span v-if="field.required">*</span></label>
                  <div v-if="field.type == 'radio'">
                    <div v-for="option in field.options" :key="option.value" class="custom-control custom-radio custom-control-inline">
                      <input type="radio" v-model="form.data[field.name]" :value="option.value" :id="option.value" :name="field.name" class="custom-control-input">
                      <label class="custom-control-label" :value="option.value" :for="option.value">{{getMLInfo(option.label)}}</label>
                    </div>
                  </div>
                  <template v-else-if="field.type == 'documents'">
                    <div v-for="(doc, index) in documents" :key="`doc-${index}`" class="mb-2 py-1">
                      <div class="doc-container p-2 bg-primary cursor-pointer d-flex" @click="downloadInteractionHandler(doc)">
                        <div class="icon bg-secondary d-flex justify-content-center align-items-center">
                          <i class="far fa-book"></i>
                        </div>
                        <div class="text pl-2 ml-1 d-flex justify-content-between align-items-center flex-grow-1">
                          <div>
                            <div class="text-uppercase small">{{ft('globals.document')}}</div>
                            <div class="font-weight-bold">{{doc.title || doc.name}}</div>
                          </div>
                          <i class="far fa-trash-alt mr-3 cursor-pointer" @click="removeDocument(index)"></i>
                        </div>
                      </div>
                    </div>
                    <div>
                      <input type="file" multiple @change="addDocument" id="documents" hidden/>
                      <label class="btn btn-dark-brown border-0 text-dark pt-2 pb-1 font-weight-normal text-uppercase" for="documents">
                        {{ft('globals.upload')}} <i class="far fa-upload ml-4"></i>
                      </label>
                    </div>
                  </template>
                  <div v-else-if="field.type == 'recipes'">
                    <div v-for="(rec, index) in recipes" :key="`rec-${index}`" class="mb-2 py-1">
                      <div class="doc-container p-2 bg-primary cursor-pointer d-flex">
                        <div class="icon bg-cover-center" :style="{'background-image': `url(${rec.thumbnail.url})`}">
                        </div>
                        <div class="text pl-2 ml-1 d-flex justify-content-between align-items-center flex-grow-1">
                          <div>
                            <div class="text-uppercase small">{{ft('globals.recipe')}}</div>
                            <div class="font-weight-bold">{{rec.title}}</div>
                          </div>
                          <i class="far fa-trash-alt mr-3 cursor-pointer" @click="removeRecipe(index)"></i>
                        </div>
                      </div>
                    </div>
                    <button class="btn btn-dark-brown border-0 text-dark pt-2 pb-1 font-weight-normal" @click.stop.prevent="showContentsModal">
                      {{ft('globals.add')}} <i class="far fa-layer-plus ml-4"></i>
                    </button>
                  </div>
                  <input
                    v-else
                    :id="field.name"
                    :type="field.type"
                    :name="field.name"
                    class="form-control"
                    :pattern="field.pattern"
                    :required="field.required"
                    v-model="form.data[field.name]"
                    :placeholder="getMLInfo(field.placeholder)"
                  />
                </div>
              </div>
              <div class="w-100 d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-dark px-5">{{content.button_text || ft('globals.ok')}}</button>
                <button class="btn btn-dark-brown text-dark border-0 px-5 ml-3" @click.stop.prevent="goBack">{{ft('globals.cancel')}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut faster">
      <div v-if="contentsModalOpened" ref="contents-modal" class="content-modal" fullscreen-modal>
        <button type="button" class="btn d-flex justify-content-center align-items-center btn-close p-2" @click="hideContentsModal">
          <i class="fal fa-times"></i>
        </button>
        <div class="content-modal-container w-100 h-100vh-header">
          <div class="container h-100 d-flex flex-column justify-content-between text-center">
            <div>
              <h1>{{ft('globals.search_recipes_title')}}</h1>
              <div v-html="ft('globals.search_recipes_description')"></div>
              <div class="row justify-content-center mt-4 mt-lg-5">
                <div class="col-12 col-md-6">
                  <input class="form-control" :placeholder="ft('globals.search_recipes_placeholder')" v-model="searchText" type="text"/>
                </div>
              </div>
              <div class="px-2 mb-3 mt-4 mt-md-5 text-left">
                {{ft('globals.results')}}: {{suggestionsTotalNumber}}
              </div>
              <div class="d-flex flex-wrap mb-4 mb-md-5">
                <div v-for="recipe in recipesSuggestions" :key="recipe.name" class="option prevent-hover-effect p-3 cursor-pointer d-flex flex-column justify-content-between mx-2 text-left mb-3" @click="recipe.checked = !recipe.checked">
                  <div class="d-flex justify-content-between">
                    <div v-if="recipe.thumbnail" :src="recipe.thumbnail.url" class="w-50 image ar-1by1 bg-cover-center" :style="{'background-image': `url(${recipe.thumbnail.url})`}"/>
                    <div class="custom-control custom-checkbox">
                      <input :checked="recipe.checked" @click="recipe.checked = !recipe.checked" type="checkbox" class="custom-control-input" :id="`checkbox-${recipe.name}`">
                      <label class="custom-control-label" :for="`checkbox-${recipe.name}`"></label>
                    </div>
                  </div>
                  <div class="recipe-title-container">
                    <div class="badge badge-danger font-weight-normal">{{ (recipe.author || {}).first_name }} {{ (recipe.author || {}).last_name }}</div>
                    <div>{{ft(`globals.${recipe.type}`)}}</div>
                    <div class="font-weight-bold recipe-title" v-if="recipe.title">{{recipe.title}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pb-5 d-flex justify-content-center">
              <button type="button" class="btn btn-dark px-5" @click="addRecipes">{{ft("globals.add")}}</button>
              <button type="button" class="btn btn-secondary ml-3 px-5" @click="hideContentsModal">{{ft("globals.cancel")}}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <modal-static-progress ref="static-backdrop" @progress-init="(data) => form.progress = data" :progress="parseInt(form.progress)"></modal-static-progress>
  </div>
</template>

<style lang="scss">
@import "../../stylesheets/page/visite_mulino_event_materials.scss";
@import "../../stylesheets/page/visite_mulino_squared_options.scss";
@import "../../stylesheets/page/visite_mulino_fullscreen_modal.scss";
[admin-page-event-component] {
  min-height: calc(100vh - #{$header-height});

  .content-modal {

    .form-control {
      background-color: #ECE4DE20 !important;
      border: 1px solid $primary !important;
      &::placeholder {
        color: #000 !important;
      }
    }

    .recipe-title-container {
      .recipe-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .option {
      border-radius: 1.25rem;
      background-color: #C9B3A265;

      .image {
        border-radius: .75rem !important;
      }
    }

    .custom-control.custom-checkbox {
      padding-left: 1rem;
    }

    .custom-control-label::before,
    .custom-control-label::after {
      left: -1rem;
    }

    .custom-control-label::before {
      border: none;
    }
  }
}
</style>