<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { get } from 'lodash';
import QRCode from "../../modules/qrcode.js";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      qrCodeModalOpened: false,
      currentStep: undefined,
      currentStepIndex:  -1,
      direction: 'forward',
      selectedVisit: null,
      qrCode: null,
      steps: [],
      tags: []
    }
  },
  created() {
    this.content.stripes.forEach(s => {
      const step = {...s};
      if (step.options_groups) {
        step.options_groups.forEach(g => {
          if (g.options_tag)
            g.options = (Fandom.getContent(g.options_tag).children || [])
              .map(c => Fandom.getContent(c))
              .filter(c => (new Date(c.valid_from) <= new Date() && new Date(c.valid_to) >= new Date()) || !c.valid_from)
        });
      }
      Vue.set(this.steps, this.steps.length, step);
    });
    this.currentStepIndex = 0;
    Vue.set(this, 'currentStep', this.steps[0]);
  },
  methods: {
    mountQrCode(text) {
      Vue.nextTick(() => {
        if (!!this.qrCode) {
          this.qrCode.clear();
          document.getElementById("qrcode").innerHTML = "";
          Vue.set(this, 'qrCode', null);
        }
        Vue.set(this, 'qrCode', new QRCode(document.getElementById("qrcode"), {
          text: text,
          width: 256,
          height: 256,
          colorDark : "#000000",
          colorLight : "#ffffff",
          correctLevel : QRCode.CorrectLevel.H
        }));
      });
    },
    selectedHandler(event) {
      if (this.currentStepIndex < this.steps.length -1 && !event.content.slug) {
        Vue.set(this.tags, this.currentStepIndex, event.content.name);
        if (!this.steps[this.currentStepIndex + 1].options_groups || this.steps[this.currentStepIndex + 1].reload_needed) {
          Fandom.getSearchableContents(
            data => {
              const mainContent = get(data, ["name_to_content", data.main_content_name], {});
              const options = (mainContent.children || []).map(c => data.name_to_content[c]);
              if (options.length > 1) {
                Vue.set(this.steps[this.currentStepIndex + 1], 'reload_needed', true);
                Vue.set(this.steps[this.currentStepIndex + 1], 'options_groups', [{}]);
                Vue.set(this.steps[this.currentStepIndex + 1].options_groups[0], 'options', options);
                this.nextHanlder();
              } else {
                this.setSelectedVisit(options[0]);
              }
            },
            {
              content_filtering: [...this.tags.map(t => [t]), "visits"],
              current_date_filter: true,
              layout: "default"
            }
          )
        } else {
          this.nextHanlder();
        }
      } else {
        this.setSelectedVisit(event.content);
      }
    },
    setSelectedVisit(selectedVisit) {
      Vue.set(this, 'selectedVisit', selectedVisit);
      this.qrCodeModalOpened = true;
      Vue.nextTick(() => {
        disableBodyScroll(this.$refs["qr-code-modal"]);
        if (this.selectedVisit) {
          Vue.nextTick(() => {
            this.mountQrCode(`${window.location.origin}${Fandom.getContentURL(this.selectedVisit)}`);
          });
        }
      });
    },
    prevHandler() {
      Vue.delete(this.tags, this.currentStepIndex);
      this.direction = "backward";
      Vue.nextTick(() => {
        this.currentStep = undefined;
        this.currentStepIndex -= 1;
      })
    },
    nextHanlder() {
      this.direction = "forward";
      Vue.nextTick(() => {
        this.currentStep = undefined;
        this.currentStepIndex += 1;
      })
    },
    closeModal() {
      clearAllBodyScrollLocks();
      this.qrCodeModalOpened = false;
    },
    setCurrentStep() {
      Vue.set(this, 'currentStep', this.steps[this.currentStepIndex]);
      $("html, body").animate({
        scrollTop: 0
      }, 0, "linear");
    },
  },
  computed: {
    qrCodePlaceholder() {
      return get(this.pageModel, ["layout","content","qrcode_missing_image"], false);
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" page-event-check-in-component>
    <h6 class="text-center mt-5 mb-0 text-uppercase" v-if="content.title">{{content.title}}</h6>
    <transition
      :enter-active-class="`animated fadeIn${direction === 'forward' ? 'Right' : 'Left'} faster`"
      :leave-active-class="`animated fadeOut${direction === 'backward' ? 'Right' : 'Left'} faster`"
      @after-leave="setCurrentStep"
    >
      <component
        v-if="currentStep"
        :is="currentStep.layout"
        :key="currentStepIndex"
        :content="currentStep"
        :opt-container-content="containerContent"
        @selected="selectedHandler"
        @prev="prevHandler"
      ></component>
    </transition>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut faster">
      <div v-if="qrCodeModalOpened" ref="qrCodeModal" class="content-modal" fullscreen-modal>
        <button type="button" class="btn d-flex justify-content-center align-items-center btn-close p-2" @click="closeModal">
          <i class="fal fa-times"></i>
        </button>
        <div class="content-modal-container w-100 h-100vh-header">
          <div class="container h-100 d-flex flex-column justify-content-between text-center">
            <template v-if="selectedVisit">
              <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-6">
                  <h1>{{ft('globals.qr_code_modal.title')}}</h1>
                  <div>{{ft('globals.qr_code_modal.description')}}</div>
                </div>
              </div>
              <a :href="selectedVisit.slug" class="mx-auto my-5 qr-code-container">
                <div id="qrcode" class="mx-auto"></div>
              </a>
            </template>
            <template v-else>
              <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-6">
                  <h1>{{ft('globals.qr_code_modal.title_empty')}}</h1>
                  <div class="mb-4">{{ft('globals.qr_code_modal.description_empty')}}</div>
                </div>
              </div>
              <img class="mx-auto my-5 qr-code-placeholder" v-if="qrCodePlaceholder" :src="qrCodePlaceholder.url"/>
            </template>
            <button type="button" class="btn btn-dark d-flex mx-auto mb-5" @click="closeModal">
              {{ft('globals.prev')}}
            </button>
            <div class="mt-3 mx-3 small text-center disclaimer" v-if="content.disclaimer" v-html="content.disclaimer"></div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[page-event-check-in-component] {
  background-color: $secondary;

  h6 {
    font-size: .875rem;
  }

  .qr-code-container {
    border-radius: 2rem;
    border: 1px solid #AE8E75;
    padding: 2rem;
  }

  img.qr-code-placeholder {
    width: 16rem;
    height: 16rem;
  }
}
</style>