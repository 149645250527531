<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';
import { get } from 'lodash';
const VISITS_MAIN_TAG_NAME = "visits";

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  data() {
    return {
      userVisits: []
    }
  },
  mounted() {
    this.getUserVisits();
  },
  methods: {
    getContentURL: Fandom.getContentURL,
    getUserVisits() {
      Fandom.getContents(
        VISITS_MAIN_TAG_NAME,
        {
          filter_by_user: true,
          layout: "system-visit-grid"
        },
        (data) => {
          (data.name_to_content[data.main_content_name].children || []).forEach( visit => {
            const visitData = data.name_to_content[visit];
            visitData.site = get(data, ["decorators", "site", visitData.decorators["site"]], {});
            visitData.link = this.getContentURL(visitData).replace("visit-", "visit-materials-");
            Vue.set(this.userVisits, this.userVisits.length, visitData);
          })
        }
      )
    }
  },
  computed: { }
};
</script>

<template>
  <div v-easyadmin="easyadminId" class="container py-4" :class="contentType" page-user-events-component>
    <div class="row justify-content-center h-100">
      <div class="col-12 col-lg-6">
        <div class="mb-4">
          <h1 class="text-center  mb-4" v-if="content.title">{{content.title}}</h1>
          <template v-if="userVisits.length > 0">
            <div v-for="visit in userVisits" :key="visit.name" class="my-2 py-1">
              <a :href="visit.link" class="visit-container p-2 bg-primary cursor-pointer d-flex deco-none text-dark">
                <div class="icon bg-secondary d-flex justify-content-center align-items-center">
                  <i class="far fa-2x fa-flask"></i>
                </div>
                <div class="text pl-2 ml-1 d-flex flex-column justify-content-center">
                  <div class="font-weight-bold">{{visit.title}}</div>
                  <div class="small">
                    {{visit.site.title}}
                    <span v-if="!!visit.site.description" v-html="`(${visit.site.description})`"></span>
                  </div>
                  <div class="small">{{getDate(visit.valid_from)}}</div>
                </div>
              </a>
            </div>
          </template>
          <div v-else>{{ft('globals.no_materials_available')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[page-user-events-component] {
  min-height: calc(100vh - #{$header-height});

  span > p {
    display: inline;
    margin-bottom: 0;
  }

  a.visit-container {
    transition: background-color .25s;
    border-radius: 1rem;
    text-decoration: none !important;

    .icon {
      width: 4rem;
      height: 4rem;
      border-radius: .75rem;
    }

    &:hover,
    &:active {
      background-color: #fff !important;
    }
  }
}
</style>