import { get } from "lodash";

const cinqueStagioniBasicMixin = {
  methods: {
    getMLInfo(info) {
      return (typeof info === 'object') ? info[globalState.lang] : info;
    },
    getParams() {
      return Fandom.getQueryStringParams();
    },
    getDate(dateString) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return (new Date(dateString)).toLocaleDateString('it-IT', options);
    },
    goBack() {
      history.back();
    },
    getEventDate(event) {
      const eventContent = typeof event == 'string' ? Fandom.getContent(event) : event;
      if (Date.parse(eventContent.valid_to) - Date.parse(eventContent.valid_from) < (60*60*24*1000))
        return this.getDate(eventContent.valid_from);
      else
        return `${this.getDate(eventContent.valid_from)} - ${this.getDate(eventContent.valid_to)}`
    }
  },
  computed: {
    thankYouUrl() {
      return this.content.thank_you_page.hasOwnProperty("url") ? this.content.thank_you_page.url : (this.content.thank_you_page || '/');
    }
  }
}

const multiLangMixin = {
  methods: {
    redirectTolang(lang) {
      return get(this, ["mainContentSlugs", lang], `/${lang}`);
    }
  },
  computed: {
    langs() {
      return get(globalState, ["pageModel", "langs"], []).filter(lang => lang !== this.currentLang);
    },
    currentLang() {
      return get(globalState, "lang", "it");
    },
    mainContentSlugs() {
      return get(globalState, ["pageModel", "name_to_content", globalState.pageModel.main_content_name, "canonical"], {});
    }
  }
};

export default cinqueStagioniBasicMixin;

export {
  multiLangMixin
}
