<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cinqueStagioniBasicMixin from '../../modules/visite_mulino_mixins_module.js';

export default {
  mixins: [fandomBasicMixin, cinqueStagioniBasicMixin],
  props: {
    formUrl: {
      type: String,
      default: "/upload_simple_form"
    }
  },
  data() {
    return {
      form: {
        success: false,
        errors: [],
        loading: false,
        data: {},
      },
      currentStepIndex: 0,
      direction: 'forward',
      showThankYouStep: false,
      currentStep: this.content.steps[0],
    }
  },
  created() {
    this.formFields.forEach(f => {
      Vue.set(this.form.data, f.name, '');
    })
  },
  mounted() {
    Vue.nextTick(disableBodyScroll);
  },
  beforeDestroy: clearAllBodyScrollLocks,
  methods: {
    setCurrentStep() {
      if (this.steps.length > this.currentStepIndex) {
        Vue.set(this, 'currentStep', this.steps[this.currentStepIndex]);
      } else {
        this.showThankYouStep = true;
      }
      $("html, body").animate({
        scrollTop: 0
      }, 0, "linear");
    },
    submitForm() {
      if (!this.form.loading) {
        this.form.success = false;
        this.formLoading = true;
        this.form.errors = null;
        let submitForm = () => {
          Fandom.ajax(
            {
              async: true,
              processData: false,
              contentType: false,
              method: "POST",
              url: this.formUrl,
              data: this.getFormData(),
              success: (data) => {
                this.form.success = data.success;
                if (this.form.success) {
                  this.form.progress = 0;
                  this.nextHandler();
                } else {
                  this.form.errors = data.errors;
                }
              }
            },
            () => {
              this.progress = null;
              this.form.loading = false;
            }
          );
        }
        submitForm();
      }
    },
    getFormData() {
      const formData = new FormData();
      formData.append("content_id", this.containerContent.id);
      formData.append("interaction_id", this.content.interaction.id);
      this.formFields.forEach(field => {
        const formDataValue = this.form.data[field.name];
        if (!!formDataValue) {
          formData.append(`user_upload_interaction[${field.name}]`, formDataValue);
        }
      });
      return formData;
    },
    selectionClickHandler(choice) {
      this.form.data[this.currentStepFormFields[0].name] = choice.id;
      if (this.currentStepIndex < this.steps.length - 1) {
        this.nextHandler();
      } else {
        this.submitForm();
      }
    },
    prevHandler() {
      this.form.data[this.currentStepFormFields[0].name] = null;
      this.direction = "backward";
      Vue.nextTick(() => {
        this.currentStep = null;
        this.currentStepIndex -= 1;
      });
    },
    nextHandler() {
      this.direction = "forward";
      Vue.nextTick(() => {
        this.currentStep = null;
        this.currentStepIndex += 1; 
      });
    }
  },
  computed: {
    progressPercentage() {
      return (this.stepNumber / this.stepsNumber) * 100;
    },
    stepsNumber() {
      return this.steps.length;
    },
    stepNumber() {
      return this.currentStepIndex + 1;
    },
    steps() {
      return this.content.steps;
    },
    formFields() {
      let fields = []
      this.content.steps.forEach(s => {
        const sFields = JSON.parse(s.form_fields || '{}').fields;
        fields = [...fields, ...sFields];
      });
      return fields;
    },
    currentStepFormFields() {
      return JSON.parse(this.currentStep.form_fields || '{}').fields;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" modal-survey-component>
    <div v-if="!showThankYouStep" class="position-relative w-100 progress-bar-container">
      <div class="position-absolute h-100 progress-bar" :style="{'width': `${progressPercentage}%`}"></div>
    </div>
    <div class="close-icon p-3 cursor-pointer" @click="$emit('close')">
      <i class="fal fa-times fa-2x"></i>
    </div>
    <div class="text-center mt-5">
      {{ft('globals.question')}} {{currentStepIndex + 1}} {{ft('globals.of')}} {{steps.length}}
    </div>
    <transition
      :enter-active-class="`animated fadeIn${direction === 'forward' ? 'Right' : 'Left'} faster`"
      :leave-active-class="`animated fadeOut${direction === 'backward' ? 'Right' : 'Left'} faster`"
      @after-leave="setCurrentStep"
    >
      <div v-if="currentStep || showThankYouStep">
        <div v-if="currentStep" :key="currentStep.$id">
          <div class="container text-center py-4 h-100">
            <div class="row justify-content-center h-100">
              <div class="col-12 col-lg-6">
                <h2 class="font-weight-bold mb-4" v-if="currentStep.title">{{content.title}}</h2>
                <div v-if="currentStepFormFields && currentStepFormFields.length > 0">
                  <div class="row">
                    <div class="form-group" v-for="field in currentStepFormFields" :key="field.name" :class="field.small ? 'col-6' : 'col-12'" >
                      <template v-if="field.type === 'selection'">
                        <div v-for="(choice, index) in field.choices" :key="`choice-${index}`">
                          <button
                            class="btn w-100 d-flex align-items-center justify-content-between py-3"
                            :id="`choice-${field.name}-${choice.id}`"
                            @click="selectionClickHandler(choice)"
                            :class="[
                              `btn-${form.data[field.name] == choice.id ? 'light' : 'primary'}`,
                              `mb-${index < (field.choices.length - 1) ? '2' : '0'}`
                            ]"
                          >
                            <label class="mb-0" :for="choice.id">
                              {{getMLInfo(choice.value)}}
                            </label>
                            <input type="radio" :id="choice.id" :value="choice.id" v-model="form.data[field.name]"/> 
                          </button>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="w-100 d-flex justify-content-center mt-4" v-if="currentStepIndex > 0">
                    <button
                      @click="prevHandler"
                      class="btn px-5"
                      :class="currentStep.button_class || 'btn-dark-brown border-0 text-dark'"
                    >{{currentStep.button_text || ft('globals.prev')}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showThankYouStep && content.thank_you_step" :key="content.thank_you_step.$id" class="text-center">
          <div class="container text-center py-4 h-100">
            <div class="row justify-content-center h-100">
              <div class="col-12 col-lg-6">
                <div>
                  <img v-if="content.thank_you_step.image" class="w-50 mx-auto" :src="content.thank_you_step.image.url"/>
                  <h1 class="text-center mt-5" v-if="content.thank_you_step.title">{{content.thank_you_step.title}}</h1>
                  <div class="text-center mt-3" v-html="content.thank_you_step.description" v-if="content.thank_you_step"></div>
                </div>
                <button class="btn btn-dark mt-5 px-5" @click="$emit('close')">{{ft('globals.close')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
[modal-survey-component] {
  background-color: $secondary;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  input[type=radio] {
    accent-color: #000;
  }

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .progress-bar-container {
    height: .3rem;
    background-color: $primary;
    .progress-bar {
      top: 0;
      left: 0;
      background-color: #735338;
      transition: width .5s ease-in-out;
    }
  }
}
</style>